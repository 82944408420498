import React from 'react';
import AddCommentButton from './AddCommentButton';
import FormTextInput from '../FormTextInput';

class HiddenCommentTextField extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      textFieldHidden: true
    };
  }
  render() {
    if (this.state.textFieldHidden && !this.props.field.value) {
      return (
        <AddCommentButton
          onClick={() => this.setState({textFieldHidden: false})}
        />
      );
    } else {
      return (
        <FormTextInput
          {...this.props}
        />
      );
    }
  }
};

export default HiddenCommentTextField;
