import React from 'react';

import HorizontalDivider from './HorizontalDivider';
import { familyHeavy } from '../../fonts';
import { usernameBlueText } from '../../colors';


const HeaderUserContents = ({user}) => (
  <span>
    <span style={styles.username}>
      {user.firstName} {user.lastName}'s
    </span>
    <span style={styles.guideinsights}>
      Guide Insights Report
    </span>
  </span>
);

const HeaderOrgContents = ({organization}) => (
  <div style={styles.orgNameContainer}>
    <span>{organization.name}</span>
  </div>
);


/**
 *  @class        Header
 *
 *  @classdesc    Header (on pages with header) with long horizontal line.  On
 *  even numbered pages, displays the "Johnny Appleseed's Guide Insights
 *  Report" and odd pages display the BP logo.
 **/
class Header extends React.Component {
  render() {
    let headerContents;
    if (this.props.pageNumber % 2 === 0) {
      headerContents = <HeaderUserContents user={this.props.user} />;
    } else {
      headerContents = <HeaderOrgContents
        organization={this.props.organization}
      />;
    }
    return (
      <div className="row" style={styles.container}>
        <div className="col-12">
          {headerContents}
          <HorizontalDivider />
        </div>
      </div>
    );
  }
};

const styles = {
  container: {
    marginBottom: '1rem'
  },
  username: {
    color: usernameBlueText,
    fontFamily: familyHeavy
  },
  guideinsights: {
    marginLeft: '0.5em'
  },
  bpLogo: {
    width: '1in'
  },
  orgNameContainer: {
    textAlign: 'right'
  }
};

export default Header;
