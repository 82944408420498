import React from 'react';
import { StyleSheet, css } from 'aphrodite';

import { turquoiseAccent, offBlack } from '../../colors';
import { familyHeavy } from '../../fonts';

export const ReviewHeading1 = ({children, additionalStyles=[]}) => (
  <h1
    className={css(styles.heading, styles.heading1, ...additionalStyles)}
  >
    {children}
  </h1>
);

export const ReviewHeading2 = ({children, additionalStyles=[]}) => (
  <h2 className={css(styles.heading, styles.heading2, ...additionalStyles)}>{children}</h2>
);

export const ReviewHeading3 = ({children, additionalStyles=[]}) => (
  <h3 className={css(styles.heading, styles.heading3, ...additionalStyles)}>
    {children}
  </h3>
);

export const ReviewHeading4 = ({children, additionalStyles=[]}) => (
  <h4 className={css(styles.heading, styles.heading4, ...additionalStyles)}>
    {children}
  </h4>
);

const styles = StyleSheet.create({
  heading: {
    fontFamily: familyHeavy,
    fontWeight: 'bold'
  },
  heading1: {
    fontSize: '72px',
    color: turquoiseAccent,
    marginTop: '70px',
    marginBottom: '80px',
    letterSpacing: '1.2px',
    lineHeight: '1.6',
  },
  heading2: {
    color: offBlack,
    fontSize: '50px',
    marginBottom: '140px',
    letterSpacing: '0.8px',    
  },
  heading3: {
    color: offBlack,
    fontSize: '36px',
    letterSpacing: '0.6px',
    marginTop: '140px',
    marginBottom: '32px'
  },
  heading4: {
    color: offBlack,
    fontSize: '24px',
    letterSpacing: '0.2px',
    marginTop: '140px',
    marginBottom: '56px',
    lineHeight: '1.69',
  },
});
