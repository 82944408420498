import React from 'react';
import { Formik, Form, FieldArray, Field } from 'formik';

import {
  ReviewHeading3
} from '../../../common/components/Headings';
import FormSubmitButton from '../FormSubmitButton';
import FormAdditionalErrorText from '../FormAdditionalErrorText';
import NoReportsCheckbox from './NoReportsCheckbox';
import StakeholderFormSection from './StakeholderFormSection';
import ExistingReviewersSection from './ExistingReviewersSection';
import {
  createValidationSchema,
  createInitialValues,
  MAX_NUM_MANAGERS,
  MAX_NUM_DIRECTREPORTS,
  MAX_ADDITIONAL,
  MAX_ADDITIONAL_WHEN_NO_DIRECT
} from '../../forms/stakeholders';
import FormErrorChecker from '../../../common/components/FormErrorChecker';


const StakeholderForm = ({onSubmit, onBack, previousPeerAnswers}) => {  
  const previousManagerAnswers = previousPeerAnswers.filter(answer => answer.peerType === "MG")
  const previousDirectReportAnswers = previousPeerAnswers.filter(answer => answer.peerType === "DR")
  const previousAdditionalAnswers = previousPeerAnswers.filter(answer => answer.peerType === "AD")

  const formValidationSchema = createValidationSchema(previousManagerAnswers.length, previousDirectReportAnswers.length, previousAdditionalAnswers.length);
  const hasPreviousValues = (previousManagerAnswers.length + previousDirectReportAnswers.length + previousAdditionalAnswers.length) > 0;

  return (
    <Formik
      initialValues={createInitialValues(hasPreviousValues)}
      validationSchema={formValidationSchema}
      // This form is so large, validateOnChange slows text input to a crawl
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={(values) => {
        // need to call `cast` so Yup inserts the default peerType
        onSubmit(formValidationSchema.cast(values));
      }}
      render={({ values, errors, isSubmitting, dirty }) => {
        console.log(errors);
        const maxAdditional = (
          values.noDirectReports ? MAX_ADDITIONAL_WHEN_NO_DIRECT : MAX_ADDITIONAL
        );
        return (
          <Form>
            <ReviewHeading3>
              Manager
              {hasPreviousValues ? '' : <Field
                name="noManagers"
                component={NoReportsCheckbox}
              />}              
            </ReviewHeading3>
            <ExistingReviewersSection existingReviewers={previousManagerAnswers}/>
            <FieldArray
              name="managers"
              render={arrayHelpers => {
                if (values.noManagers) {
                  return null;
                } else {
                  return (
                    <StakeholderFormSection
                      values={values}
                      peerTypeLabel="Manager"
                      formParentName="managers"
                      arrayHelpers={arrayHelpers}
                      maxNumPeers={MAX_NUM_MANAGERS}
                      existingCount={previousManagerAnswers.length}
                      alwaysShowRemove={hasPreviousValues > 0}
                    />
                  );
                }
              }}
            />

          <ReviewHeading3>
            Direct Reports
            {hasPreviousValues ? '' : <Field
              name="noDirectReports"
              component={NoReportsCheckbox}
            />}
          </ReviewHeading3>
          <ExistingReviewersSection existingReviewers={previousDirectReportAnswers}/>
          <FieldArray
            name="directReports"
            render={arrayHelpers => {
              if (values.noDirectReports) {
                return null;
              } else {
                return (
                  <StakeholderFormSection
                    values={values}
                    peerTypeLabel="Direct Report"
                    formParentName="directReports"
                    arrayHelpers={arrayHelpers}
                    maxNumPeers={MAX_NUM_DIRECTREPORTS}
                    existingCount={previousDirectReportAnswers.length}
                    alwaysShowRemove={hasPreviousValues > 0}
                  />
                );
              }
            }}
          />
          <ReviewHeading3>Additional Key Stakeholders</ReviewHeading3>
          <ExistingReviewersSection existingReviewers={previousAdditionalAnswers}/>
          <FieldArray
            name="additionalStakeholders"
            render={arrayHelpers => (
              <StakeholderFormSection
                values={values}
                peerTypeLabel="Stakeholder"
                formParentName="additionalStakeholders"
                arrayHelpers={arrayHelpers}
                maxNumPeers={maxAdditional}
                existingCount={previousAdditionalAnswers.length}
                alwaysShowRemove={hasPreviousValues > 0}
              />
            )}
          />
          <Field
            // need this additional error text here to cover corner cases
          // where the amount of additionalStakeholders is invalid.  This
          // can happen because the number of allowed additional stakeholders
          // depends on the "no direct reports" checkbox.
          name="additionalStakeholders"
          component={FormAdditionalErrorText}
        />
            <FormErrorChecker
              isSubmitting={isSubmitting}
              errors={errors}
            >
              {hasError => (
                <FormSubmitButton hasError={hasError} text="Submit" onBack={onBack} isDirty={dirty} />
              )}
            </FormErrorChecker>
      </Form>
        )
      }}
    />
  );
};

export default StakeholderForm;
