import React from 'react';

import Page from './components/Page';
import { familyMedium } from '../fonts';
import { sizeLg } from './fontSizes';

const CoverPage = ({user}) => (
  <Page>
    <div style={styles.coverPage}>
        <p style={styles.reportTitle}>Guide Insights Report</p>
        <p>Prepared for:</p>
        <p style={styles.name}>{user.firstName} {user.lastName}</p>
    </div>
  </Page>
);

const styles = {
  reportTitle: {
    marginBottom: '2em',
    fontSize: sizeLg,
    fontFamily: familyMedium
  },
  coverPage: {
    height: '100%',
    paddingTop: '3in',
    textAlign: 'center'
  },
  name: {
    fontSize: sizeLg,
    fontFamily: familyMedium
  }
};

export default CoverPage;
