export const PEER_TYPE = {
  MANAGER: 'MG',
  DIRECT_REPORT: 'DR',
  ADDITIONAL: 'AD'
};

// for denoting the self ratings as separate from peer ratings
export const SELF_TYPE = 'SELF';

export const PEER_TYPE_LIST = Object.keys(PEER_TYPE).map(k => PEER_TYPE[k]);

export const REVIEW_TYPE = {
  SELF_REVIEW: 'S',
  PEER_REVIEW: 'P'
};

export const REVIEW_SECTION_TYPE = {
  INTRODUCTION: 0,
  ORGANIZATION: 1,
  PERSONAL: 2,
  STAKEHOLDERS: 3
};

function createReviewSection (props) {
  return Object.assign({
    active: false,
    finished: false
  }, props);
}

/**
 *  Given a review instance, retrieve the reviewee's GoalAnswer.
 **/
export const getGoalAnswer = function (review) {
  switch (review.reviewType) {
      // if this is a self review, assume we want the GoalAnswer attached
    case REVIEW_TYPE.SELF_REVIEW:
      return review.goalAnswer;
      // if this is a peer review, we are asking for the associated
      // self review's GoalAnswer
    case REVIEW_TYPE.PEER_REVIEW:
      return review.selfReview.goalAnswer;
    default:
      return null;
  }
}

export const getReviewIsComplete = function (review, reviewCycle) {
  const { coreValues } = reviewCycle;
  const { numCompletedPages, reviewType } = review;

  switch (reviewType) {
    case REVIEW_TYPE.SELF_REVIEW:
      return numCompletedPages === coreValues.length + 3;
    case REVIEW_TYPE.PEER_REVIEW:
      return numCompletedPages === coreValues.length + 2;
    default:
      throw new Error(`Do not know how to handle reviewType ${reviewType}`);
  }
}

/**
 *  The length of the review is dynamic based on the number of CoreValues in
 *  the ReviewCycle.  This returns a datastructure to use in determining which
 *  page of the review we are currently on (`review.numCompletedPages` <=
 *  `maxPageNum`) and for rendering navigation.
 *
 *  @param  {object}  review - The Review model.
 *  @param  {number} [previousPage] - An optional counter to be used if navigating to previous pages
 **/
export const getReviewSections = function (review, previousPage) {
  const coreValues = review.reviewCycle.coreValues;

  const reviewSectionsByReviewType = {
    [REVIEW_TYPE.SELF_REVIEW]: [
      createReviewSection({
        type: REVIEW_SECTION_TYPE.INTRODUCTION,
        maxPageNum: 0,
        numPages: 1
      }),
      createReviewSection({
        type: REVIEW_SECTION_TYPE.ORGANIZATION,
        maxPageNum: coreValues.length,
        numPages: coreValues.length
      }),
      createReviewSection({
        type: REVIEW_SECTION_TYPE.PERSONAL,
        maxPageNum: coreValues.length + 1,
        numPages: 1
      }),
      createReviewSection({
        type: REVIEW_SECTION_TYPE.STAKEHOLDERS,
        maxPageNum: coreValues.length + 2,
        numPages: 1
      })
    ],
    /**
     *  In a peer review, there is no Stakeholders page.
     **/
    [REVIEW_TYPE.PEER_REVIEW]: [
      createReviewSection({
        type: REVIEW_SECTION_TYPE.INTRODUCTION,
        maxPageNum: 0,
        numPages: 1
      }),
      createReviewSection({
        type: REVIEW_SECTION_TYPE.ORGANIZATION,
        maxPageNum: coreValues.length,
        numPages: coreValues.length
      }),
      createReviewSection({
        type: REVIEW_SECTION_TYPE.PERSONAL,
        maxPageNum: coreValues.length + 1,
        numPages: 1
      })
    ]
  };

  // get ReviewSection list depending on type of review
  const reviewSections = reviewSectionsByReviewType[review.reviewType];

  // determine "active" section and which sections are finished based on the
  // section's `maxPageNum` and the Review's number completed pages.
  let i;
  let completedCounter;
  // If previousPage is non-null, it means the back button has been hit, and we should
  // now use this value to compare against maxPageNum in the for loop to determine which 
  // section is active
	if (previousPage !== null && previousPage !== undefined) {
		completedCounter = previousPage;
	} else {
		completedCounter = review.numCompletedPages;
	}
  for (i = 0; i < reviewSections.length; i++) {
    const reviewSection = reviewSections[i];
    if (completedCounter <= reviewSection.maxPageNum) {
      reviewSection.active = true;
      break;
    } else {
      reviewSection.finished = true;
    }
  }

  return reviewSections;

}
