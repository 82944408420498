import React from 'react';
import InfoPageHeading from './components/InfoPageHeading';
import InfoPageSubheading from './components/InfoPageSubheading';

import InfoPage from './components/InfoPage';
import Paragraph from './components/Paragraph';

const WhyFeedbackPage = ({...props}) => (
  <InfoPage {...props}>
    <InfoPageHeading>
      Why is feedback<br />
      important?
    </InfoPageHeading>
    <InfoPageSubheading>
      Great organizations lead with a clear purpose and put their people first. They create the conditions for teams to be greater than the sum of their parts. Together, people can have a greater impact than if they were alone.
    </InfoPageSubheading>
    <Paragraph>Effective collaboration doesn’t happen by accident and the relationships among a team should be given as much attention as any critical system within an organization. Productive, transparent feedback is critical to managing those relationships effectively.</Paragraph>
    <Paragraph>The payoff for great, ongoing feedback? A team of people with strong working relationships in and out of the organization that promote healthy cultural dynamics, provide for both consistency and flexibility, and drive results for the organization.</Paragraph>
    <Paragraph heavy={true}>Why transparent feedback?</Paragraph>
    <Paragraph>Great feedback is personal to the receiver, relevant to their work, and highly actionable in service to making changes where they are needed. Transparency ensures that the feedback is grounded in the relationship, providing critical context to deliver on these three requirements.</Paragraph>
  </InfoPage>
);

export default WhyFeedbackPage;
