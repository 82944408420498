import React from 'react';

import { REVIEW_TYPE } from '../../models';

const getReviewerFirstName = function (review) {
  switch (review.reviewType) {
    case REVIEW_TYPE.SELF_REVIEW:
      return review.user.firstName;
    case REVIEW_TYPE.PEER_REVIEW:
      return review.reviewer.firstName;
    default:
      throw new Error(`Unknown reviewType ${review.reviewType}`);
  }
}

const ReviewerFirstName = ({review}) => (
  <span>{getReviewerFirstName(review)}</span>
);

export default ReviewerFirstName;
