import React from 'react';

import {ReportHeading} from '../Headings';

const style = {
  position: 'absolute',
  width: '100%'
};

const PlotYLabel = ({top, text}) => (
  <div style={Object.assign({top}, style)}>
    <ReportHeading>{text}</ReportHeading>
  </div>
);

export default PlotYLabel;
