import React from 'react';
import { Field } from 'formik';

import { ReviewHeading4 } from '../../common/components/Headings';
import FormSelectInput from './FormSelectInput';
import ZeroPaddedIndex from '../../common/components/ZeroPaddedIndex';

/**
 *  @class        TalentFormSection
 *
 *  @classdesc    For selecting a talent and optionally filling in why text.
 **/
const TalentFormSection = ({talentOptions, index, ...props}) => (
  <div>
    <ReviewHeading4>
      Talent <ZeroPaddedIndex index={index} />
    </ReviewHeading4>

    <Field
      name={`talentAnswers[${index}]talent`}
      component={FormSelectInput}
      label="Select a talent"
      placeholder="Make a selection"
      options={talentOptions}
      {...props}
    />

    {/* Hiding this field at request of Blank Page, since we don't surface the value on the raw data page. Remove in the future if we decide not to add it back in. */}
    {/* <Field
      name={`talentAnswers[${index}]why`}
      component={HiddenCommentTextField}
      label="Why did you select this talent? (optional)"
      charLimit={talentWhyCharLimit}
      {...props}
    /> */}
  </div>
);
export default TalentFormSection;
