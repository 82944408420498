import React from 'react';

import { sizeSm } from '../fontSizes';
import { ValueAverageNumber, ValueSelfNumber } from './ScoreNumbers';
import Paragraph from './Paragraph';
import { grayAccent } from '../../colors';

const styles = {
  container: {
    paddingTop: '2%',
    paddingBottom: '2%'
  },
  numberCol: {
    paddingTop: '3.25em'
  },
  numberColRight: {
    borderLeft: `2px solid ${grayAccent}`
  },
  smallItalic: {
    fontSize: sizeSm,
    fontStyle: 'italic'
  }
};

const CoreValuePageScoreRow = ({coreValue}) => (
  <div className="row" style={styles.container}>
    <div
      className="col"
      style={styles.numberCol}
    >
      <div className="text-center">
        <ValueAverageNumber value={coreValue.peerRatingAverage} />
        <Paragraph noMargin={true}>Average Score<br />
          <span style={styles.smallItalic}>Does not include self-score</span></Paragraph>
      </div>
    </div>
    <div
      className="col"
      style={{...styles.numberCol, ...styles.numberColRight}}
    >
      <div className="text-center">
        <ValueSelfNumber value={coreValue.selfRatingAverage} />
        <Paragraph noMargin={true}>Self Score</Paragraph>
      </div>
    </div>
  </div>
);

export default CoreValuePageScoreRow;
