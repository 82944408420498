import React from 'react';

import Page from './components/Page';
import Header from './components/Header';
import InfoPageHeading from './components/InfoPageHeading';
import InfoPageSubheading from './components/InfoPageSubheading';
import StakeholderStoryWordsSection from './components/StakeholderStoryWordsSection';

import {
  PEER_TYPE
} from '../models';

const styles = {
  managerStoryWordsContainer: {
    marginTop: '1.75in',
    marginBottom: '1in'
  },
  additionalStoryWordsContainer: {
  }
}

const StakeholdersPerspectivePage = ({storyWordsByPeerType, ...props}) => (
  <Page>
    <Header {...props} />

    <InfoPageHeading smallTopMargin={true}>Stakeholders’<br />
      Perspective</InfoPageHeading>

    <InfoPageSubheading>The is how others described you in six words.</InfoPageSubheading>

    <StakeholderStoryWordsSection
      storyWordsByPeerType={storyWordsByPeerType}
      peerType={PEER_TYPE.MANAGER}
      styles={styles.managerStoryWordsContainer}
    />

    <StakeholderStoryWordsSection
      storyWordsByPeerType={storyWordsByPeerType}
      peerType={PEER_TYPE.ADDITIONAL}
      styles={styles.additionalStoryWordsContainer}
    />
  </Page>
);

export default StakeholdersPerspectivePage;
