import React from 'react';
import InfoPage from './components/InfoPage';
import InfoPageHeading from './components/InfoPageHeading';
import InfoPageSubheading from './components/InfoPageSubheading';
import Paragraph from './components/Paragraph';

const MappingYourPerceptionsPage = ({...props}) => (
  <InfoPage {...props}>
    <InfoPageHeading>Mapping Your 
      Initial Perceptions</InfoPageHeading>
    <InfoPageSubheading>Using the simple grid to the right, identify your trends, themes, differences and similarities as: strengths, challenges, or opportunities, and map whether they were a surprise, or not.
    </InfoPageSubheading>
    <Paragraph>For those messages that come as more of a surprise, determine if they highlight new areas of strength to celebrate, or, an opportunity to develop for you to be successful.</Paragraph>

    <Paragraph>Even if something wasn’t a surprise, feel free to mark it as a potential opportunity.</Paragraph>
  </InfoPage>
);

export default MappingYourPerceptionsPage;
