import React from 'react';
import InfoPage from './components/InfoPage';
import InfoPageHeading from './components/InfoPageHeading';
import InfoPageSubheading from './components/InfoPageSubheading';
import Paragraph from './components/Paragraph';

const HowToThinkPage = ({...props}) => (
  <InfoPage {...props}>

    <InfoPageHeading>How to Think<br />
    About Your Feedback</InfoPageHeading>
    <InfoPageSubheading>The point of asking for feedback is to help you be your best self. Consider these tips to help you work with your data constructively.</InfoPageSubheading>
    <Paragraph heavy={true}>Keep an Open Mind</Paragraph>

    <Paragraph>Discovering relevant gaps between how you see yourself and how others see you can lead to powerful insights. As you digest your feedback, remember, we all have a choice in moments of learning—how will you choose to receive your feedback? Your responders care about you and your success—that’s why they took the time to give you feedback.</Paragraph>

    <Paragraph heavy={true}>Maintain Perspective</Paragraph>

    <Paragraph>Your feedback messages are not mandates, or even judgments. In this experience, you can compare others’ perceptions of you to your own self-assessment. It is not uncommon for different stakeholders to have different perceptions and expectations of their experiences with you, and this will be reflected in your feedback results.  </Paragraph>

    <Paragraph heavy={true}>Approach Blind Spots with Curiosity</Paragraph>

    <Paragraph>Leadership journeys are aspirational – forward looking. Fundamental to that journey is our awareness of the “whole board,” ensuring that we don’t have something in our blind spots that we aren’t aware of. Blind spots are easy to check when we look at them, and seeking feedback is a great way to ensure there is nothing outside of our visibility that might be holding us back. If you find feedback that surprises you, consider reframing it as something you’ve simply discovered in your blind spot, seek understanding, and develop your awareness so you can see it moving forward.</Paragraph>
  </InfoPage>
);

export default HowToThinkPage;
