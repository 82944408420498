import React from 'react';

import { sizeLg } from '../fontSizes';

const InfoPageSubheading = ({children}) => (
  <p style={style}>{children}</p>
);

const style = {
  fontSize: sizeLg
};

export default InfoPageSubheading;
