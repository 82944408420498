import React from 'react';

import Page from './components/Page';
import Header from './components/Header';
import {ReportHeading} from './components/Headings';
import Paragraph from './components/Paragraph';
import HorizontalBarChart from './components/HorizontalBarChart';

const TalentsChartPage = ({talents, ...props}) => (
  <Page>
    <Header {...props} />

    <div className="row">
      <div className="col-3">
        <ReportHeading>Leadership Talents <br />
          Chosen by Stakeholders<br />
        (Frequency of each talent)</ReportHeading>
      </div>
      <div className="col-9">
        <ReportHeading marginBottom="1em">How do your talents align?</ReportHeading>
        <Paragraph>Look at the leadership talents that your stakeholders chose as being important for you to focus on. How do those compare to the two you chose? What might be causing any misalignments? This is where follow-up conversations become useful.</Paragraph>
      </div>
    </div>

    <div className="row mt-5">
      <div className="col">

        <HorizontalBarChart
          data={talents.map(talent => ({
              label: talent.name,
              value: talent.peerTalentAnswers.length
          }))}
          barsNumColumns={9}
        />
      </div>
    </div>

  </Page>
);

export default TalentsChartPage;
