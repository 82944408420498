// because Object.keys(new Date()).length === 0;
// we have to do some additional check
export function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

/**
 * Function that checks whether two arrays are equal
 * 
 * @param {array} arr1 
 * @param {array} arr2 
 */
export function doArraysMatch(arr1, arr2) {
  // Unequal if different lengths
  if (arr1.length !== arr2.length) return false

  // Unequal if items are not in same location in both arrays
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false
  }

  // Otherwise arrays are equal
  return true
}