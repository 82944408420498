import React from 'react';
import axios from 'axios';
import { StyleSheet, css } from 'aphrodite';
import { isMobile } from 'react-device-detect';

import ContainerWithSidebar from '../common/components/ContainerWithSidebar';
import Navigation from './components/Navigation';
import ReviewWelcome from './ReviewWelcome';
import ReviewCoreValue from './ReviewCoreValue';
import ReviewPersonal from './ReviewPersonal';
import ReviewStakeholders from './ReviewStakeholders';
import ReviewThanks from './ReviewThanks';
import ReviewFinished from './ReviewFinished';
import { getReviewSections, REVIEW_SECTION_TYPE, REVIEW_TYPE } from '../models';
import { getMuiTheme, getOrgStyles } from '../styles';
import { MuiThemeProvider  } from '@material-ui/core/styles';


const API_URI = process.env.REACT_APP_API_URI;

const REVIEW_SECTION_MAIN_COMPONENTS = {
  [REVIEW_SECTION_TYPE.INTRODUCTION]: ReviewWelcome,
  [REVIEW_SECTION_TYPE.ORGANIZATION]: ReviewCoreValue,
  [REVIEW_SECTION_TYPE.PERSONAL]: ReviewPersonal,
  [REVIEW_SECTION_TYPE.STAKEHOLDERS]: ReviewStakeholders
};

/**
 *  @class        Review
 *
 *  @classdesc    Top-level component to make API calls for fetching Review
 *  and submitting answers from all forms.  Decides which page of Review
 *  to render.
 **/
class Review extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      review: null,
      // save last value of numCompletedPages, this is how we know if review
      // was just finished, or was finished in a different browser session
      lastNumCompletedPages: null,
      // this is used to keep track of the number of previously completed
      // pages, so that we can decrement the counter to step back to previous
      // page states
      currentPageNum: null,
      hideTalents: false,
    };

    /**
     *  Called when an API request fails.  TODO: Handle this properly.
     **/
    this.handleRequestError = () => {
      console.log("ERROR submitting form.");
    };


    /**
     *  A helper method to add the `review` attribute to a model object.
     *
     *  @param  {Object}  model - An object representing a model instance from
     *  our REST API.
     **/
    const addReview = (model) => (
      Object.assign({
        review: this.state.review.id
      }, model)
    );

    /**
     *  Helper method called after each Review page data is submitted,
     *  increments `numCompletedPages` on the Review, sets the state
     *  of this component and this causes the page to re-render to next
     *  page of the Review.
     **/
    this.handleReviewPageComplete = () => {
      this.setState({ currentPageNum: this.state.currentPageNum + 1 })
      axios.patch(
        // update review numCompletedPages
        `${API_URI}/reviews/${this.state.review.uuid}/`,
        {
          numCompletedPages: this.state.currentPageNum
        }
      ).then((resp) => {
        // update review state
        this.setState({
          review: resp.data,
          lastNumCompletedPages: resp.data.numCompletedPages
        }, () => window.scrollTo(0, 0));
      }).catch(this.handleRequestError);

    };

    /**
     *  Handle submitting form from Goals page.  In the case of the peer
     *  review, no data is submitted so we just move the Review page forward.
     *  In a self review, we submit the GoalAnswer.
     **/
    this.handleGoalsFormSubmit = (values) => {
      const review = this.state.review;
      switch (review.reviewType) {
        case REVIEW_TYPE.SELF_REVIEW:
          // POST if there isn't already a value for goalAnswer
          if (review.goalAnswer === null) {
            axios
            .post(`${API_URI}/goalanswers/`, addReview(values))
            .then(this.handleReviewPageComplete)
            .catch(this.handleRequestError);
          } else {
            // PATCH if there is already a value for goalAnswer
            axios
            .patch(`${API_URI}/goalanswers/${review.goalAnswer.id}/`, values)
            .then(this.handleReviewPageComplete)
            .catch(this.handleRequestError)
          }
          break;
        case REVIEW_TYPE.PEER_REVIEW:
          this.handleReviewPageComplete();
          break;

        default:
          break;
      }
    };

    /**
     *  Submit form from each page in the "Organization" section of the
     *  Review, from the API perspective we are creating a new CoreValueAnswer,
     *  then moving to the next page of the review.
     **/
    this.handleCoreValueFormSubmit = (values) => {
      const coreValueAnswers = values.coreValueAnswers;

      const requestData = coreValueAnswers.map(addReview);

      // If IDs exist on our data, then we're updating previous answers
      if (requestData[0].id) {
        axios.patch(
          `${API_URI}/corevalueanswers/`,
          requestData
        ).then(this.handleReviewPageComplete)
        .catch(this.handleRequestError);
      } else {
        axios.post(
          `${API_URI}/corevalueanswers/`,
          requestData
        ).then(this.handleReviewPageComplete)
        .catch(this.handleRequestError);
      }
    };

    /**
     *  Submit form on the "Personal" section of the review.
     *  Makes two requests, one for StoryWord and one for TalentAnswer
     *  if talents aren't hidden.
     *
     **/
    this.handlePersonalFormSubmit = (values) => {
      const hideTalents = this.state.hideTalents;
      const newStoryWordsRequestData = values.storyWords.map(addReview);
      const newTalentAnswersRequestData = values.talentAnswers ? values.talentAnswers.map(addReview) : null;
      const talentDataExists = newTalentAnswersRequestData && newTalentAnswersRequestData.some(talentAnswer => talentAnswer.id);
      const storyWordDataExists = newStoryWordsRequestData.some(storyWord => storyWord.id);
      const axiosMethod = talentDataExists || storyWordDataExists ? 'PATCH' : 'POST';

      const requests = [
        axios({
          method: axiosMethod,
          url: `${API_URI}/storywords/`,
          data: newStoryWordsRequestData,
        }),
        ...(!hideTalents
          ? [
              axios({
                method: axiosMethod,
                url: `${API_URI}/talentanswers/`,
                data: newTalentAnswersRequestData,
              }),
            ]
          : []),
      ];

      axios.all(requests)
        .then(this.handleReviewPageComplete)
        .catch(this.handleRequestError)
    };

    /**
     *  Submit "Stakeholders" review page.  Makes one request to create a
     *  PeerAnswer for each stakeholder specified in the form.
     **/
    this.handleStakeholdersFormSubmit = (values) => {
      let peerAnswerRequestData = [];

      peerAnswerRequestData = peerAnswerRequestData.concat(
        values.noManagers ? [] : values.managers.map(addReview),
        values.additionalStakeholders.map(addReview),
        values.noDirectReports ? [] : values.directReports.map(addReview)
      );
      axios.post(
        `${API_URI}/peeranswers/`,
        peerAnswerRequestData
      ).then(this.handleReviewPageComplete).catch(this.handleRequestError);
    };

    /**
     *  Back button handler
     **/
    this.onBack = () => {
			const previousPage = this.state.currentPageNum - 1;
			this.setState({ currentPageNum: previousPage });
    };

    this.onBackToBeginning = () => {
			this.setState({ currentPageNum: 0 });
		};
  };
  /**
   *  On mount, fetch the Review instance using the UUID in the URL.
   **/
  componentDidMount () {
    axios.get(`${API_URI}/reviews/${this.props.match.params.reviewUUID}/`)
      .then((resp) => {
        this.setState({
          review: resp.data,
          currentPageNum: resp.data.numCompletedPages,
          hideTalents: resp.data.reviewCycle.hideTalents
        });
      })
      .catch(function (err) {
        console.error(`ERROR fetching review: ${err}`);
      });
  }
  getOnSubmitHandler (sectionType) {
    switch (sectionType) {
      case REVIEW_SECTION_TYPE.INTRODUCTION:
        return this.handleGoalsFormSubmit;
      case REVIEW_SECTION_TYPE.ORGANIZATION:
        return this.handleCoreValueFormSubmit;
      case REVIEW_SECTION_TYPE.PERSONAL:
        return this.handlePersonalFormSubmit;
      case REVIEW_SECTION_TYPE.STAKEHOLDERS:
        return this.handleStakeholdersFormSubmit;
      default:
        throw new Error(`No onSubmit handler for section type ${sectionType}`);
    }
  }
  render() {
    if (isMobile) {
      return <div className={css(styles.mobileText)}>Please use your desktop to use Guide Insights</div>
    }

    const review = this.state.review;
    if (!review) {
      // TODO: handle loading
      return <div></div>;
    }

    const organization = review.reviewCycle.organization;
    const orgStyles = getOrgStyles(organization);
    const muiTheme = getMuiTheme(organization);

    let mainSection = null;

    const reviewSections = getReviewSections(review, this.state.currentPageNum);
    // for the current review section
    const activeReviewSection = reviewSections.find(section => section.active);
    if (activeReviewSection) {
			// render main
			const ReviewMainColumnComponent = REVIEW_SECTION_MAIN_COMPONENTS[activeReviewSection.type];
			mainSection = <ReviewMainColumnComponent review={review} onSubmit={this.getOnSubmitHandler(activeReviewSection.type)} orgStyles={orgStyles} onBack={this.onBack} currentPageNum={this.state.currentPageNum} hideTalents={this.state.hideTalents} />;
		} else {
			if (this.state.lastNumCompletedPages === null) {
				mainSection = <ReviewFinished review={review} orgStyles={orgStyles} onBack={this.onBack} onBackToBeginning={this.onBackToBeginning}/>;
			} else {
				mainSection = <ReviewThanks review={review} orgStyles={orgStyles} onBack={this.onBack} onBackToBeginning={this.onBackToBeginning}/>
			}
		}

    return (
      <MuiThemeProvider theme={muiTheme}>
        <ContainerWithSidebar
          sidebarContents={
            <Navigation review={review} orgStyles={orgStyles} pageNum={this.state.currentPageNum - 1} hideTalents={this.state.hideTalents} />
          }
          mainContents={mainSection}
          orgStyles={orgStyles}
        />
      </MuiThemeProvider>
    );
  }
};

const styles = StyleSheet.create({
  mobileText: {
    marginTop: 80,
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 22,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export default Review;
