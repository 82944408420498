import React from 'react';

const style = {
  pageBreakAfter: 'always',
  height: '10in' // minus 0.5 * 2 margin (specified in Report.scss)
};

const Page = ({children}) => (
  // each page is a bootstrap container.
  <div
    className="container-fluid"
    style={style}
  >
    {children}
  </div>
);
export default Page;
