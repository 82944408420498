/**
 *  @file     personal.js
 *
 *  @desc     Form validation logic and form field helper text for the
 *  personal review pages.
 **/

import * as Yup from 'yup';
import _ from 'lodash';

import {
  FIELD_REQUIRED_TEXT,
  makeCharLimitText
} from './common';

export const talentWhyCharLimit = 400;

// number of TalentAnswers entered on the Personal form
const NUM_TALENTANSWERS = 2;
// number of StoryWords entered on the personal form
export const NUM_STORYWORDS = 6;

/**
 *  Create validation schema for the Personal form, where TalentAnswers and
 *  StoryWords are submitted, or where StoryWords are submitted if talents are hidden.
 **/
export const createValidationSchema = function (hideTalents) {
  return Yup.object().shape({
    /**
     *  talentAnswers: [{
     *    talent: 1,
     *    why: "Because I'm the greatest."
     *  }]
     **/
    ...(
        !hideTalents && {
          talentAnswers: Yup.array()
            .min(NUM_TALENTANSWERS)
            .max(NUM_TALENTANSWERS).of(
              Yup.object().shape({
                // the talent id
                talent: Yup.number()
                .min(1, FIELD_REQUIRED_TEXT)
                .required(FIELD_REQUIRED_TEXT),
                // the why field of the TalentAnswer
                why: Yup.string()
                  .max(
                    talentWhyCharLimit,
                    makeCharLimitText(talentWhyCharLimit)
                  )
                  .notRequired()
              }).test(
                'unique-talent-id',
                'Chosen talents must be unique',
                /**
                 *  This test ensures talents cannot be chosen more than once.
                 **/
                function (value) {
                  // get all non-zero talents (selected talents)
                  const selectedTalents = _.filter(
                    this.options.parent,
                    (val) => val.talent !== 0
                  );

                  // count by talentIds
                  const talentCounts = _.countBy(
                    selectedTalents,
                    (val) => val.talent
                  );

                  // if this field's selected talent has been chosen more than once,
                  // validation error
                  return value.talent === 0 || talentCounts[value.talent] === 1;
                }
              )
            )
          .required(FIELD_REQUIRED_TEXT)
        }
      )
    ,
    /**
     *  storyWords: ["word", "another", ...]
     **/
    storyWords: Yup.array().min(NUM_STORYWORDS).max(NUM_STORYWORDS).of(
      Yup.object().shape({
        text: Yup.string()
        .required(FIELD_REQUIRED_TEXT)
        .matches(/^[^\s]*$/, "Please enter a single word")
        .trim()
        .lowercase()
      }).test(
        'unique-story-word',
        'Story words must be unique',
        /**
         *  This test ensures words are unique.
         **/
        function (value) {
          // get all words that have been filled
          const nonEmptyWords = this.options.parent.filter(word => word.text !== '');

          // count occurences
          const wordCounts = _.countBy(
            nonEmptyWords,
            (val) => val.text
          );

          // if this word is non-empty and has been entered more than once,
          // validation error
          return value === '' || wordCounts[value.text] === 1;
        }
      )
    ).required(FIELD_REQUIRED_TEXT)
  });
};

export const createInitialValues = function (previousTalentAnswers = [], previousStoryWords = [], hideTalents) {
  const formInitialValues = {
    storyWords: [],
    ...(!hideTalents && { talentAnswers: [] })
  };

  for (let i = 0; i < NUM_STORYWORDS; i++) {
    formInitialValues.storyWords[i] = previousStoryWords.length ? {
      id: previousStoryWords[i].id,
      text: previousStoryWords[i].text,
    } : "";
  }

  if (!hideTalents) {
    for (let i = 0; i < NUM_TALENTANSWERS; i++) {
      formInitialValues.talentAnswers[i] =  {
        ...(previousTalentAnswers.length && { id: previousTalentAnswers[i].id }),
        ...(previousTalentAnswers.length ? { talent: previousTalentAnswers[i].talent } : 0),
        why: ''
      };
    }
  }

  return formInitialValues;
}