import React from 'react';
import { StyleSheet, css } from 'aphrodite';

import {
  familyMedium
} from '../../fonts';


/**
 *  @class        ContainerWithSidebar
 *
 *  @classdesc    A wrapper used on every Review page and on the AllData page
 *  for the main white section next to the navbar.  Modify the bootstrap grid
 *  properties here to change the sizing of the main section relative to the
 *  sidebar.
 **/
const ContainerWithSidebar = ({
  sidebarContents,
  mainContents,
  orgStyles
}) => (
  <div className={"container-fluid " + css(styles.container)}>
    <div className="row">
      <div className={
        // this is the width of the navbar and its ability to show and hide
        // responsively
        `d-none d-sm-none d-md-block col-md-3 col-xl-2 ${css(
          styles.sidebarContainer,
          orgStyles.primaryColorBackground
        )}`
      }>
        {sidebarContents}
      </div>
      <div className={
        // this is the overall width of the white main section
        "col-xs-12 col-md-9 col-xl-10"
      }>
        {mainContents}
      </div>
    </div>
  </div>
);

const styles = StyleSheet.create({
  container: {
    fontFamily: familyMedium,
    fontSize: '18px',
    lineHeight: '1.61',
    minHeight: '100%',
  },
  sidebarContainer: {
    color: 'white',
    maxWidth: '240px',
    minHeight: '100vh',
  },
});

export default ContainerWithSidebar;
