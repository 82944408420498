import React from 'react';

const MainColumn = ({children}) => (
  <div className="row">
    <div className="col-xs-12 col-md-8 offset-md-1 col-xl-6 offset-xl-2">
      {children}
    </div>
  </div>
);

export default MainColumn;
