import React from 'react';

import { familyHeavy } from '../../fonts';
import { sizeXxl, sizeXxxl } from '../fontSizes';
import {
  turquoiseAccent,
  SELF_COLOR,
  black
} from '../../colors';

const format = (value) => (
  (Math.floor(value * 10) / 10.0).toFixed(1)
);

export const InsightsScoreNumber = ({ value, extraStyles={} }) => (
  <span style={Object.assign(
    {},
    styles.insightsScore,
    styles.black,
    styles.scoreNumber,
    extraStyles,
  )}>{
    format(value)
  }</span>
);

export const ValueAverageNumber = ({value}) => (
  <span style={Object.assign(
    {},
    styles.valueScore,
    styles.black,
    styles.scoreNumber
  )}>{format(value)}</span>
);

export const ValueSelfNumber = ({value}) => (
  <span style={Object.assign(
    {},
    styles.valueScore,
    styles.selfBlue,
    styles.scoreNumber
  )}>{format(value)}</span>
);

export const InlineScoreNumber = ({value}) => (
  <span style={Object.assign(
    {},
    styles.scoreNumber,
    styles.inline
  )}>{format(value)}</span>
);

const styles = {
  black: {
    color: black,
  },
  turquoise: {
    color: turquoiseAccent,
  },
  selfBlue: {
    color: SELF_COLOR
  },
  valueScore: {
    fontSize: sizeXxl,
  },
  insightsScore: {
    fontSize: sizeXxxl,
  },
  inline: {
  },
  scoreNumber: {
    fontFamily: familyHeavy,
    display: 'inline-block',
    lineHeight: 1
  }
};
