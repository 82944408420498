import React from 'react';
import { Formik, Form } from 'formik';
import FormSubmitButton from './FormSubmitButton';
import { ReviewHeading3 } from '../../common/components/Headings';

/**
 *  Not much of a form, just displays the goal with a next button.
 **/
const GoalsFormPeer = ({review, onSubmit}) => (
  <Formik
    onSubmit={onSubmit}
    render={() => (
      <Form>
        <ReviewHeading3>{review.user.firstName}'s Feedback Goal</ReviewHeading3>
        <p>{review.selfReview.goalAnswer.goal}</p>
        <ReviewHeading3>Why It's Important</ReviewHeading3>
        <p>{review.selfReview.goalAnswer.goalWhy}</p>
        <FormSubmitButton text="Next" showBack={false} />
      </Form>
    )}
  />
);

export default GoalsFormPeer;

