import { StyleSheet } from 'aphrodite';
import { createMuiTheme } from '@material-ui/core/styles';

import { turquoiseAccent, errorRed, navDarkTeal } from './colors';
import { familyMedium } from './fonts';

/**
 *  Create styles given an organization, these styles are used throughout
 *  the review as accent colors.
 *
 *  @param  {Object}  organization - The Organization model from the API.  If
 *  undefined, default colors will be used.
 **/
export function getOrgStyles (organization) {
  // defaults
  let secondaryColor = turquoiseAccent;
  let primaryColor = navDarkTeal;
  if (organization) {
    secondaryColor = organization.secondaryColor;
    primaryColor = organization.primaryColor;
  }
  return StyleSheet.create({
    secondaryColorText: {
      color: secondaryColor
    },
    secondaryColorBackground: {
      backgroundColor: secondaryColor
    },
    secondaryColorBorder: {
      borderColor: secondaryColor
    },
    // a 40% transparency
    secondaryColorTransparentBackground: {
      backgroundColor: `${secondaryColor}66`
    },
    primaryColorBackground: {
      backgroundColor: primaryColor
    }
  });
}

/**
 *  Create the MaterialUI color scheme using colors from the Organization model
 *  from the API.
 *
 *  @param  {Object}  organization - The Organization model from the API. If
 *  undefined, default colors will be used.
 **/
export function getMuiTheme (organization) {
  // default
  let main = turquoiseAccent;
  if (organization && organization.secondaryColor) {
    main = organization.secondaryColor;
  }
  return createMuiTheme({
    palette: {
      primary: {
        main,
        contrastText: "white"
      },
      secondary: {
        main: errorRed
      }
    },
    typography: {
      useNextVariants: true,
      fontFamily: familyMedium
    }
  });
  
}
