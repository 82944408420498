import React from 'react';
import getVideoId from 'get-video-id';
import { StyleSheet, css } from 'aphrodite';

/**
 *  @class        WelcomeVideo
 *
 *  @classdesc    Displays the welcome video on the welcome page.  For now
 *  only youtube is handled, though the `get-video-id` module can take 
 *  other URLs to embed.
 **/
const WelcomeVideo = ({reviewCycle}) => {
  const {id, service} = getVideoId(reviewCycle.welcomeVideo);

  switch (service) {
    case 'youtube':
      return (
        <div className={css(styles.container)}>
          <iframe
            id="ytplayer"
            title="Welcome"
            type="text/html"
            width="640"
            height="360"
            src={`https://www.youtube.com/embed/${id}?autoplay=0&origin=http://example.com&rel=0`}
            frameBorder="0"></iframe>
        </div>
      );
    
    default:
      console.log(`ERROR: video service ${service} not implemented.`);
      return <div></div>;
  }
};

const styles = StyleSheet.create({
  container: {
    marginTop: '3em',
    marginBottom: '3em'
  }
});

export default WelcomeVideo;
