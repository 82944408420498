import React from 'react';
import {Formik, Field, Form} from 'formik';

import FormSubmitButton from './FormSubmitButton';
import FormTextInput from './FormTextInput';
import { ReviewHeading3 } from '../../common/components/Headings';

import {
  createInitialValues,
  createValidationSchema,
  goalCharLimit,
  goalWhyCharLimit
} from '../forms/welcome';
import FormErrorChecker from '../../common/components/FormErrorChecker';


/**
 *  @class        GoalsFormSelf
 *
 *  @classdesc    Displays GoalAnswer form on welcome page.
 **/
class GoalsFormSelf extends React.Component {
  
  render() {
    const previousValue = this.props.review.goalAnswer
    
    return (
      <Formik
        initialValues={previousValue ? createInitialValues(previousValue.goal, previousValue.goalWhy) : createInitialValues()}
        validationSchema={createValidationSchema()}
        onSubmit={this.props.onSubmit}
        render={({ errors, isSubmitting, dirty }) => (
          <Form>
            <ReviewHeading3>Set a Goal for Feedback</ReviewHeading3>
            <p>Use the space below to share your goal and why it's important to you. Be thoughtful, as your goal sets the context, providing clarity on why you are doing this and why it's important to you. Giving your stakeholders this direction allows them to provide more relevant feedback.</p>
            <p>Please note, these are the only two answers that will be shared from this survey. The remainder of this self-assessment will be used to compare your responses to those of your stakeholders through the report you will receive.</p>
            <Field
              name="goal"
              component={FormTextInput}
              label="Please write your personal goal below"
              charLimit={goalCharLimit}
            />
            <ReviewHeading3>Why is your goal important?</ReviewHeading3>
            <Field
              name="goalWhy"
              component={FormTextInput}
              label="Please enter the “why” behind your goal below"
              charLimit={goalWhyCharLimit}
            />
            <FormErrorChecker
              isSubmitting={isSubmitting}
              errors={errors}
            >
              {hasError => (
                <FormSubmitButton
                  hasError={hasError}
                  text="Next"
                  showBack={false}
                  isDirty={dirty}
                />
              )}
            </FormErrorChecker>
          </Form>
        )}
      />
    );
  }
};

export default GoalsFormSelf;
