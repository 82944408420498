import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Button from '@material-ui/core/Button'

import ReviewMainColumn from './components/ReviewMainColumn';
import { ReviewHeading1 } from '../common/components/Headings';
import { familyMedium } from '../fonts';
import { REVIEW_TYPE } from '../models';
import { getOrgStyles } from '../styles';

const getCopyText = (review) => {
  switch (review.reviewType) {
    case REVIEW_TYPE.SELF_REVIEW:
      return (
        <div>
          <p>Thank you for taking the time to do your self-evaluation. We'll now send a link to your stakeholders so that they can begin to give you feedback. Make sure you have reached out to them and given them a heads up that you're looking for their candid feedback.</p> 
          <p>We'll be sending a follow-up email because we believe in continuously improving your experience with Guide Insights. If you'd like to provide us feedback on how we can make Guide Insights better, please visit the survey link we send.</p>
        </div>
      );
    case REVIEW_TYPE.PEER_REVIEW:
      return (
        <div>
          <p>Thank you for taking the time to give valuable feedback to {review.selfReview.user.firstName}. Your responses will be used to help {review.selfReview.user.firstName} create a development strategy that is highly personalized and actionable.</p> 
          <p>We'll be sending a follow-up email because we believe in continuously improving your experience with Guide Insights. If you'd like to provide us feedback on how we can make Guide Insights better, please visit the survey link we send.</p>
        </div>
      );
    default:
      throw new Error(`No subheading text for reviewType ${review.reviewType}`);
  }
}

const ReviewThanks = ({review, onBack, onBackToBeginning}) => {
  const orgStyles = getOrgStyles(review.reviewCycle.organization);
  const atMaxReviewers = review.peerAnswers.length >= 20;
  
  return (
    <ReviewMainColumn review={review}>
      <div className={"row " + css(styles.container)}>
        <div className="col">
          <ReviewHeading1
            additionalStyles={[
              styles.thanksHeading,
              orgStyles.secondaryColorText
            ]}
          >
            Thanks!
          </ReviewHeading1>

          {getCopyText(review)}
          
          {review.reviewType === REVIEW_TYPE.SELF_REVIEW ? 
            <Button
              variant="outlined"
              color="primary"
              className={css(styles.button)}
              onClick={onBack}
              disabled={atMaxReviewers}
            >
              {atMaxReviewers ? 'Stakeholder Limit Reached' : 'Add More Stakeholders'}
            </Button> : ''
          }
          {review.reviewType === REVIEW_TYPE.PEER_REVIEW ? 
            <Button
              variant="outlined"
              color="primary"
              className={css(styles.button)}
              onClick={onBackToBeginning}
            >
              Change Your Feedback
            </Button> : ''
          }          
          <br/>
          { review.reviewType === REVIEW_TYPE.SELF_REVIEW ? 
            <Button
              className={css(styles.changeEvaluationButton)}
              onClick={onBackToBeginning}
            >
              Change Your Evaluation
            </Button> : ''
          }
        </div>
      </div>
    </ReviewMainColumn>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: '6em',
  },
  thanksHeading: {
    marginBottom: '0.1em',
    marginTop: 0
  },
  thanksSubHeading: {
    marginTop: 0,
    fontFamily: familyMedium,
    fontWeight: 'normal'
  },
  button: {
    textTransform: 'uppercase',
    fontSize: '15px',
    lineHeight: '1.05',
    letterSpacing: '1.3px',
    paddingTop: '13px',
    paddingBottom: '11px',
    paddingRight: '23px',
    borderColor: '#0000001e',
    marginTop: '60px',
  }, 
  changeEvaluationButton: {
    marginTop: '25px',
    color: '#293037',
    borderBottom: '2px solid #293037',
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    borderRadius: 0,
    marginLeft: '37px',
  }  
});

export default ReviewThanks;
