import React from 'react';
import moment from 'moment';

import MainColumn from '../../common/components/MainColumn';
import {
  ReviewHeading1,
  ReviewHeading4
} from '../../common/components/Headings';
import ReviewerFirstName from '../../common/components/ReviewerFirstName';
import PeerReviewStatusTable from './PeerReviewStatusTable';
import { getOrgStyles } from '../../styles';

import {
  getReviewIsComplete
} from '../../models';

const PeerReviewStatusText = ({
  numCompletedPeerReviews,
  peerReviews,
  reviewCycle
}) => {

  let text;

  if (numCompletedPeerReviews === peerReviews.length) {
    text = 'All of your stakeholders have taken the time to submit feedback - nothing else is needed to complete your report.';
  } else if (numCompletedPeerReviews >= reviewCycle.minPeersNeeded) {
    text = 'At this point, you have enough submissions to create a report but feel free to nudge anyone who\'s feedback you are particularly interested in including to submit before the deadline.'
  } else {
    text = `You do not have enough submissions to create a report - ensure that ${reviewCycle.minPeersNeeded - numCompletedPeerReviews} more stakeholders submit in advance of the deadline to ensure we can generate a report.`;
  }
  return (
    <p>{text}</p>
  );
};

const StatusMain = ({review}) => {
  const { peerReviews, reviewCycle } = review;

  const orgStyles = getOrgStyles(reviewCycle.organization);

  const numCompletedPeerReviews = peerReviews.filter(peerReview => (
      getReviewIsComplete(peerReview, reviewCycle)
  )).length;

  return (
    <MainColumn>
      <ReviewHeading1 additionalStyles={[orgStyles.secondaryColorText]}>
        Hello, <ReviewerFirstName review={review} />.
      </ReviewHeading1>

      <ReviewHeading4>
        {numCompletedPeerReviews} of {peerReviews.length} people have given you feedback!
      </ReviewHeading4>

      <p>Greetings, we wanted to provide you with a report of your Guide Insights feedback submissions from your stakeholders so far. If you’d like, you can touch base with your stakeholders and encourage them to submit feedback on or before the deadline of {
        moment(reviewCycle.endTimeIso).calendar()
      }. However, we will be sending your stakeholders reminders as well on a weekly basis.</p>

      <PeerReviewStatusText
        numCompletedPeerReviews={numCompletedPeerReviews}
        peerReviews={peerReviews}
        reviewCycle={reviewCycle}
      />

      <PeerReviewStatusTable
        peerReviews={peerReviews}
        reviewCycle={reviewCycle}
      />

  </MainColumn>
  );
};

export default StatusMain;
