import React from 'react';

import {
  familyHeavy
} from '../../fonts';
import {
  SELF_COLOR
} from '../../colors';

const styles = {
  heavy: {
    fontFamily: familyHeavy
  },
  accent: {
    color: SELF_COLOR,
  },
  noMargin: {
    margin: 0
  },
  italic: {
    fontStyle: 'italic'
  }
}

const Paragraph = ({
  heavy=false,
  accent=false,
  noMargin=false,
  italic=false,
  children
}) => (
  <p style={
    {...(
      heavy ? styles.heavy : {}
    ), ...(
      accent ? styles.accent : {}
    ), ...(
      noMargin ? styles.noMargin : {}
    ), ...(
      italic ? styles.italic : {}
    )}
  }>{children}</p>
);

export default Paragraph;
