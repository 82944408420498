import React from 'react';
import { Field } from 'formik';
import { StyleSheet, css } from 'aphrodite';

import { ReviewHeading4 } from '../../../common/components/Headings';
import ZeroPaddedIndex from '../../../common/components/ZeroPaddedIndex';
import FormTextInput from '../FormTextInput';
import RemoveButton from './RemoveButton';

/**
 *  @class        StakeholderFieldGroup
 *
 *  @classdesc    Section of stakeholder form for adding info for a single
 *  stakeholder.  Used for all stakeholder types, hance the `peerTypeLabel`
 *  for displaying "Manager", "Direct Report", etc. and `formParentName` for
 *  knowing how to key into the form to get the form field names.
 **/

const StakeholderFieldGroup = ({
  peerTypeLabel,
  formParentName,
  index,
  onRemoveClick,
  alwaysShowRemove,
  ...props
}) => (
  <div className={css(styles.container)}>
    <div className="row">
      <div className="col d-flex align-items-center">
        <ReviewHeading4 additionalStyles={[styles.peerHeading]}>
          {peerTypeLabel} <ZeroPaddedIndex index={index} />
        </ReviewHeading4>
      </div>
      <div className="col d-flex align-items-center justify-content-end">
          {(index > 0 || alwaysShowRemove) && <RemoveButton onClick={onRemoveClick} />}
      </div>
    </div>

    <Field
      name={`${formParentName}[${index}]firstName`}
      component={FormTextInput}
      label="First name"
      multiline={false}
      {...props}
    />

    <Field
      name={`${formParentName}[${index}]lastName`}
      component={FormTextInput}
      label="Last name"
      multiline={false}
      {...props}
    />

    <Field
      name={`${formParentName}[${index}]email`}
      component={FormTextInput}
      label="Email"
      multiline={false}
      type="email"
      placeholder="@"
      {...props}
    />

    <Field
      name={`${formParentName}[${index}]title`}
      component={FormTextInput}
      label="Title (optional)"
      multiline={false}
      {...props}
    />

    <Field
      name={`${formParentName}[${index}]phone`}
      component={FormTextInput}
      label="Phone number (optional)"
      placeholder="123"
      multiline={false}
      {...props}
    />
  </div>
);

const styles = StyleSheet.create({
  container: {
    marginTop: '3em',
  },
  peerHeading: {
    margin: 0
  }
});

export default StakeholderFieldGroup;
