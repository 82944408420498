import React from 'react';

import HorizontalBarChart from './components/HorizontalBarChart';
import Page from './components/Page';
import Header from './components/Header';
import HorizontalDivider from './components/HorizontalDivider';
import { ReportHeading } from './components/Headings';
import VerticalDivider from './components/VerticalDivider';
import { InsightsScoreNumber } from './components/ScoreNumbers';
import { PEER_TYPE } from '../models';
import { navDarkTeal, SELF_COLOR } from '../colors';

function getGiverTitle(giverType) {
  return {
    [PEER_TYPE.MANAGER]: 'Manager',
    [PEER_TYPE.DIRECT_REPORT]: 'Direct Reports',
    [PEER_TYPE.ADDITIONAL]: 'Other Key Stakeholders',
    'self': 'Self',
  }[giverType];
}

const styles = {
  barChartRow: {
    marginTop: '3em',
    marginBottom: '3em',
    // this makes sure the bar chart row takes up most of the vertical space
    height: '30%'
  },
  bottomRow: {
    marginTop: '2em',
  },
  scoreNumberContainer: {
    height: '100%'
  }
};

/**
 *  @class        CoreValueAveragePage
 *
 *  @classdesc    This page displays the averages of all core values and the
 *  big "insights score".  Entire layout is implemented here because it is
 *  unique.
 **/
const FeedbackGiverCategoryPage = ({
  insightsScoresByGiverType,
  selfInsightsScore,
  ...props
}) => (
  <Page>
    <Header {...props} />
    <div className="row">
      <div className="col-4">
        <ReportHeading>
          Guide Insights Score by<br />
          Feedback Giver Category
        </ReportHeading>
      </div>
    </div>

    { /* This flex stuff centers the bar chart vertically */}
    <div
      className="d-flex flex-column justify-content-center"
      style={styles.barChartRow}
    >
      <HorizontalBarChart
        data={insightsScoresByGiverType.map(({ key, score }) => ({
          key: key,
          label: getGiverTitle(key),
          value: score,
        }))}
        maxValue={5}
        barsNumColumns={7}
        isGiverChart
      />
    </div>
    <div className="row">
      <div className="col">
        <HorizontalDivider />
      </div>
    </div>
    <div className="row" style={styles.bottomRow}>
      <div className="col-4">
        <ReportHeading marginBottom={'2em'}>
          Extra Data Points
        </ReportHeading>
        <p>
          Most people have a natural curiosity to know how they “compare”
          with others whenever there is data being gathered about them.
          While benchmarking can be valuable in some situations, your
          data is based on the responders you chose, your relationships
          with them, and the operating norms you work within.
        </p>
        <p>
          For these reasons, it's best to compare against yourself so
          that you can understand any gaps between how you believe you
          are showing up and how others experience you.
        </p>
      </div>
      <div className="col-1 pl-4">
        <VerticalDivider />
      </div>
      <div className="col-7">
        <div className="row">
          <div className="col">
            <ReportHeading>
              Self Score
            </ReportHeading>
          </div>
        </div>
        <div
          className="d-flex flex-column justify-content-center text-center"
          style={styles.scoreNumberContainer}
        >
          <InsightsScoreNumber
            extraStyles={{ color: SELF_COLOR }}
            value={selfInsightsScore}
          />
        </div>
      </div>
    </div>
  </Page>
);

FeedbackGiverCategoryPage.defaultProps = {
  insightsScoresByGiverType: [],
};

export default FeedbackGiverCategoryPage;
