import { PEER_TYPE } from './models';

export const grayAccent = "#9b9b9b";
export const turquoiseAccent = "#00babc";
export const turquoiseAccentTransparent = 'rgba(0, 186, 188, 0.4)';
export const navDarkTeal = "#003c3c";
export const offBlack = "#1d252c";
export const transparentBlack = 'rgba(0, 0, 0, 0.6)';
export const placeholderGrey = "rgba(167, 167, 167, 0.87)";
export const errorRed = "rgb(229, 74, 84)";
export const lightGrayText = "#aeb4b8";
export const lightGrayAccent = "#dedde0";
export const darkGrayText = "rgba(41, 48, 55, 0.5)";
export const infoGrayText = "rgb(83, 91, 99)";
export const usernameBlueText = "#3454d1";
export const black = "#000000";

export const barChartColor = "#5B6670";

export const SELF_COLOR = "#004fff";
export const PEER_TYPE_COLORS = {
  [PEER_TYPE.MANAGER]: "#ff404c",
  [PEER_TYPE.DIRECT_REPORT]: "#04d9c4",
  [PEER_TYPE.ADDITIONAL]: "#0000a8",
};
