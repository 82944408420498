import React from 'react';

import { StyleSheet, css } from 'aphrodite';
import AddButton from './AddButton';

import { placeholderGrey } from '../../../colors';

const AddButtonSection = ({
  onAddClick,
  maxNumPeers,
  currentNumPeers
}) => (
  <div className={css(styles.ReviewPeerFormAddButtonSectionContainer)}>
    <div className={css(styles.flexContainer)}>
      <AddButton
        onClick={onAddClick}
        disabled={currentNumPeers >= maxNumPeers}
      />
    </div>
    <div className={"align-items-center " + css(styles.flexContainer, styles.helperContainer)}>
      <p className={css(styles.helperText)}>
        Can add up to {maxNumPeers} total
      </p>
    </div>
  </div>
);

const styles = StyleSheet.create({
  ReviewPeerFormAddButtonSectionContainer: {
    marginTop: '3em'
  },
  flexContainer: {
    display: 'inline-flex'
  },
  helperContainer: {
    marginLeft: '24px'
  },  
  helperText: {
    fontSize: '15px',
    color: placeholderGrey,
    margin: 0
  }
})

export default AddButtonSection;
