import React from 'react';
import { getIn } from 'formik';
import { withStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import { sizeSm } from '../../fonts';

const styles = theme => ({
  container: {
    width: '100%',
  },
  helperText: {
    fontSize: sizeSm,
    marginTop: '2em',
    marginBottom: '2em'
  }
});

/**
 *  @class        FormAdditionalErrorText
 *
 *  @classdesc    To be used within a formik.Form to display additional error
 *  text that is not associated with an existing Field, such as on a parent
 *  in the validation schema.
 **/
const FormAdditionalErrorText = ({field, form, classes}) => {
  const touched = getIn(form.touched, field.name);
  let errors = getIn(form.errors, field.name);
  errors = typeof errors === 'string' ? errors : null;
  return (
    <FormControl
      error={Boolean(touched && errors)}
      classes={{
        root: classes.container
      }}
    >
      <FormHelperText
        classes={{
          root: classes.helperText
        }}
      >{touched && errors}</FormHelperText>
    </FormControl>
  );
}

export default withStyles(styles)(FormAdditionalErrorText);
