import React from 'react';

import { StyleSheet, css } from 'aphrodite';
import AddIcon from '@material-ui/icons/Add';
import TextIconButton from '../TextIconButton';

import { placeholderGrey } from '../../../colors';

function AddCommentButton(props) {
  return (
    <div className={css(styles.container)}>      
      <div className={css(styles.flexContainer)}>
        <TextIconButton
          iconComponent={AddIcon}
          colorName="primary"
          text="add comment"
          {...props}
        />
      </div>
      <div className={"align-items-center " + css(styles.flexContainer, styles.helperContainer)}>
        <p className={css(styles.helperText)}>
          Add context to your rating. (Optional)
        </p>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: '2em'
  },
  flexContainer: {
    display: 'inline-flex'
  },
  helperContainer: {
    marginLeft: '24px'
  },
  helperText: {
    fontSize: '15px',
    color: placeholderGrey,
    margin: 0,
  }
})

export default AddCommentButton;

