import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { getReviewSections, REVIEW_SECTION_TYPE } from '../../models';
import { getOrgStyles } from '../../styles';

import {
  transparentBlack
} from '../../colors';


/**
 *  For each review section, this is the number of minutes estimated for each
 *  page within that section.  Most sections only have one page.
 **/
const ESTIMATED_PAGE_MINUTES = {
  [REVIEW_SECTION_TYPE.INTRODUCTION]: 5,
  [REVIEW_SECTION_TYPE.ORGANIZATION]: 3,
  [REVIEW_SECTION_TYPE.PERSONAL]: 7,
  [REVIEW_SECTION_TYPE.STAKEHOLDERS]: 10
};

function getDurations (review, currentPageNum) {
  // Grab the page count from the new variable being used
  const reviewSections = getReviewSections(review, currentPageNum);
  const finishedSections = reviewSections.filter(section => section.finished);
  const activeSection = reviewSections.find(section => section.active === true);

  if (!activeSection) {
    return {
      totalDuration: 0,
      completedDuration: 0
    };
  }

  // total duration of review
  const totalDuration = reviewSections.reduce((sum, section) => (
      sum + section.numPages * ESTIMATED_PAGE_MINUTES[section.type]
  ), 0.0);

  // total duration of finished sections
  const finishedSectionsDuration = finishedSections.reduce((sum, section) => (
      sum + section.numPages * ESTIMATED_PAGE_MINUTES[section.type]
  ), 0.0);

  // also incorporate number of pages we've finished in the current section,
  // in case it is a multi-page section
  const activeSectionNumPagesRemaining = (
    activeSection.maxPageNum - currentPageNum + 1
  );
  const activeSectionFinishedNumPages = (
    activeSection.numPages - activeSectionNumPagesRemaining
  );
  const activeSectionFinishedDuration = (
    activeSectionFinishedNumPages * ESTIMATED_PAGE_MINUTES[activeSection.type]
  );

  const completedDuration = (
    finishedSectionsDuration + activeSectionFinishedDuration
  );

  return {
    totalDuration,
    completedDuration
  };
}

/**
 *  @class        TimeRemaining
 *
 *  @classdesc    Displays minutes remaining text and the blue bar.
 **/
const TimeRemaining = ({review, currentPageNum}) => {
  const {totalDuration, completedDuration} = getDurations(review, currentPageNum);
  const orgStyles = getOrgStyles(review.reviewCycle.organization);
  return (
    <div className={css(styles.container)}>
      <p className={css(styles.progressText)}>
        Estimated time to finish: {totalDuration - completedDuration} minutes
      </p>
      <div
        className={css(
          styles.progressBarContainer,
          orgStyles.secondaryColorTransparentBackground
        )}
      >
        <div
          className={css(
            styles.progressBarProgress,
            orgStyles.secondaryColorBackground
          )}
          style={{width: `${100 * (completedDuration / totalDuration)}%`}}
        ></div>
      </div>
    </div>
  )
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    fontSize: '12px',
    letterSpacing: '1.2px',
    textTransform: 'uppercase',
    color: transparentBlack
  },
  progressBarContainer: {
    width: '100%',
    height: '4px',
  },
  progressBarProgress: {
    height: '100%'
  },
  progressText: {
    marginBottom: '0.5em'
  }
});

export default TimeRemaining;
