import React from 'react';
import axios from 'axios';

import ContainerWithSidebar from '../common/components/ContainerWithSidebar';
import AllDataMain from './components/AllDataMain';
import { getMuiTheme, getOrgStyles } from '../styles';
import { MuiThemeProvider  } from '@material-ui/core/styles';

const API_URI = process.env.REACT_APP_API_URI;

/**
 *  @class        AllData
 *
 *  @classdesc    Top-level component for rendering the page which lists the
 *  "raw data" - shows the reviewee all of the answers by each stakeholder.
 **/
class AllData extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      report: null
    };
  }
  componentDidMount () {
    /**
     * We use the `reports` API endpoint because it has everything we need,
     * same data as rendering the .pdf report but we do less aggregating of
     * it here.
     **/
    axios.get(`${API_URI}/reports/${this.props.match.params.reportUUID}/`)
      .then((resp) => {
        this.setState({
          report: resp.data
        });
      })
      .catch(function (err) {
        console.log("err");
        console.log(err);
      });
  }
  render() {
    const report = this.state.report;
    if (!report) {
      return null;
    }
    const organization = report.review.reviewCycle.organization;
    const orgStyles = getOrgStyles(organization);
    const muiTheme = getMuiTheme(organization);

    return (
      <MuiThemeProvider theme={muiTheme}>
        <ContainerWithSidebar
          mainContents={
            <AllDataMain report={this.state.report} orgStyles={orgStyles} />
          }
          orgStyles={orgStyles}
        />
      </MuiThemeProvider>
    );
  }
};

export default AllData;
