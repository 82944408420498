import React from 'react';
import { grayAccent } from '../../colors';

class VerticalDivider extends React.Component {
  render() {
    return (
      <div style={style}></div>
    );
  }
};

const style = {
  height: '100%',
  width: '2px',
  backgroundColor: grayAccent
};

export default VerticalDivider;
