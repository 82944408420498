import React from 'react';
import InfoPage from './components/InfoPage';
import InfoPageHeading from './components/InfoPageHeading';
import InfoPageSubheading from './components/InfoPageSubheading';
import Paragraph from './components/Paragraph';

import {
  familyHeavy
} from '../fonts';

const heavyNumberStyle = {
  fontFamily: familyHeavy
};

const DefineOrOthersWillPage = ({...props}) => (
  <InfoPage {...props} >
    <InfoPageHeading>Define your brand, 
      or others will.</InfoPageHeading>
    <InfoPageSubheading>Now that you have seen your description of yourself versus others’ perspective, identify the brand that will differentiate you.</InfoPageSubheading>
    <Paragraph heavy={true}>
      Cross- Reference
    </Paragraph>

    <Paragraph>
      <span style={heavyNumberStyle}>1.</span> Cross off all the words that you feel capture the same meaning as your own words. 
      <br />
      <span style={heavyNumberStyle}>2.</span> Circle words that you like, but you didn’t use to describe yourself.
    </Paragraph>

    <Paragraph heavy={true}>
      Go Broad
    </Paragraph>

    <Paragraph>
      <span style={heavyNumberStyle}>3.</span> Do any of the circled words represent the brand that you want to be known for? 
      <br />
      <span style={heavyNumberStyle}>4.</span> If so, pull those words forward and/or add new ones if you ‘d like.
    </Paragraph>

    <Paragraph heavy={true}>
      Go Narrow
    </Paragraph>

    <Paragraph>
      <span style={heavyNumberStyle}>5.</span> Using all the words you aspire to (your own and others’), choose six.
      <br />
      <span style={heavyNumberStyle}>6.</span> If you had to choose one word that creates an immediate and direct connection between you and your stakeholders, what would it be?
      <br />
      <span style={heavyNumberStyle}>7.</span> If you delivered on the promise of this word everyday, what would it look like, feel like, and sound like for yourself and your stakeholders?
      <br />
      <span style={heavyNumberStyle}>8.</span> Before you move on, review your feedback data and flag areas that are relevant to your one word.
    </Paragraph>
  </InfoPage>
);

export default DefineOrOthersWillPage;
