import React from 'react';

const NUM_COLUMNS = 3;

const StakeholderStoryWordsList = ({storyWords}) => {

  const wordsPerColumn = Math.floor(storyWords.length / NUM_COLUMNS);
  const columnStoryWords = [];
  let i;
  for (i = 0; i < NUM_COLUMNS; i++) {
    const start = i * wordsPerColumn;
    let end;
    if (i === NUM_COLUMNS - 1) {
      end = storyWords.length;
    } else {
      end = (i + 1) * wordsPerColumn;
    }
    columnStoryWords[i] = storyWords.slice(start, end);
  }

  return (
    <div className="row">
      {columnStoryWords.map((storyWords, i) => (
        <div className="col" key={i}>
          {storyWords.map(storyWord => (
              <div key={storyWord.id}>{storyWord.text}</div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default StakeholderStoryWordsList;
