import React from 'react';
import InfoPage from './components/InfoPage';
import InfoPageHeading from './components/InfoPageHeading';
import InfoPageSubheading from './components/InfoPageSubheading';
import Paragraph from './components/Paragraph';

const DevelopingLeadershipTalentsPage = ({...props}) => (
  <InfoPage {...props}>
    <InfoPageHeading>Developing Leadership Talents</InfoPageHeading>
    <InfoPageSubheading>Leadership talents represent the integration of critical skills, experience, and wisdom to accelerate results and drive impact.</InfoPageSubheading>
    <Paragraph>
      These talents work across behaviors and cultural norms, bringing the best you have to offer to everything you do and contribute to building trust and confidence across your relationships.
    </Paragraph>
    <Paragraph>
      We are never done learning, growing and, developing. To ensure you spend your time, energy, and money in the most impactful way, your stakeholders have identified the two talents that they believe will provide the greatest return on investment if you focus your development in those areas.
    </Paragraph>
    <Paragraph>
      You also chose two areas, and by reconciling and understanding what you believe will have the greatest impact with what your stakeholders have shared, you can create a development strategy for yourself that will be rewarding for you while resulting in the greatest possible positive impact on those around you. 
    </Paragraph>
  </InfoPage>
);

export default DevelopingLeadershipTalentsPage;
