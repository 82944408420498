import React from 'react';
import { getIn } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import { offBlack } from '../../colors';

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  label: {
    fontSize: '0.8em',
    color: offBlack,
    position: 'relative',
    marginBottom: '1em'
  }
});

/**
 *  @class        FormSelectInput
 *
 *  @classdesc    A dropdown select form field.
 **/
class FormSelectInput extends React.Component {
  render() {
    const { classes, field, form } = this.props;
    const touched = getIn(form.touched, field.name);
    const errors = getIn(form.errors, field.name);
    return (
      <FormControl
        required
        className={classes.formControl}
        error={Boolean(touched && errors)}
			>
        <InputLabel
          htmlFor={field.name}
          shrink={false}
          FormLabelClasses={{
            root: classes.label
          }}
        >
          {this.props.label}
        </InputLabel>
        <Select
          value={field.value}
          onChange={field.onChange}
          name={field.name}
          displayEmpty
          className={classes.selectEmpty}
        >
          <MenuItem value="" disabled>
            {this.props.placeholder}
          </MenuItem>
          {this.props.options.map(option => (
              <MenuItem value={option.value} key={option.value}>{option.text}</MenuItem>
          ))}
        </Select>
        <FormHelperText>{touched && errors}</FormHelperText>
      </FormControl>
    );
  }
}

export default withStyles(styles)(FormSelectInput);

