import React from 'react';
import axios from 'axios';

import ContainerWithSidebar from '../common/components/ContainerWithSidebar';
import StatusMain from './components/StatusMain';
import { getMuiTheme, getOrgStyles } from '../styles';
import { MuiThemeProvider  } from '@material-ui/core/styles';

const API_URI = process.env.REACT_APP_API_URI;

class Status extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      report: null
    };
  }
  componentDidMount () {
    /**
     * We use the `reports` API endpoint because it has everything we need,
     * same data as rendering the .pdf report but we do less aggregating of
     * it here.
     **/
    axios.get(`${API_URI}/reports/${this.props.match.params.reportUUID}/`)
      .then((resp) => {
        this.setState({
          report: resp.data
        });
      })
      .catch(function (err) {
        console.log("err");
        console.log(err);
      });
  }
  render() {
    if (!this.state.report) {
      return null;
    }
    const orgStyles = getOrgStyles(
      this.state.report.review.reviewCycle.organization
    );
    const muiTheme = getMuiTheme(
      this.state.report.review.reviewCycle.organization
    );
    return (
      <MuiThemeProvider theme={muiTheme}>
        <ContainerWithSidebar
          orgStyles={orgStyles}
          mainContents={
            <StatusMain
              review={this.state.report.review}
            />
          }
        />
      </MuiThemeProvider>
    );
  }
};

export default Status;
