import React from 'react';
import * as d3 from 'd3';

import {
  grayAccent
} from '../../../colors';
import { PEER_TYPE, SELF_TYPE } from '../../../models';
import PlotBubble from './PlotBubble';
import PlotYLabel from './PlotYLabel';
import PlotXLabel from './PlotXLabel';
import PeerBubbles from './PeerBubbles';

// label text along the y-axis corresponding to a rating
const RATING_LABELS = [{
  rating: 1,
  text: "Missing"
}, {
  rating: 2,
  text: "2"
}, {
  rating: 3,
  text: "Meeting"
}, {
  rating: 4,
  text: "4"
}, {
  rating: 5,
  text: "Exceeding"
}];

const styles = {
  ratingLabelContainer: {
    position: 'relative',
    height: '100%'
  },
  questionLabelContainer: {
    position: 'relative',
    width: '100%',
    height: 100
  },
};

const LEFT_PADDING = 30;
const BOTTOM_PADDING = 5;

class CoreValueQuestionsPlot extends React.Component {
  constructor (props) {
    super(props);

    this.containerRef = React.createRef();
    this.yAxisRef = React.createRef();
    this.xAxisRef = React.createRef();
  }

  render() {
    const { coreValue, height, width } = this.props;

    const containerStyle = {
      height: height + 2.0 * BOTTOM_PADDING,
      width: width + 2.0 * LEFT_PADDING
    };
    
    if (!coreValue) {
      return null;
    }

    // this scale maps the ratings to the y-axis, note the extra .5 on either
    // end per mockups.
    const yScale = d3.scaleLinear()
      .domain([0.5, 5.5])
      .range([height, 0]);

    // this scale defines the sections of the plot for each question
    const xQuestionsScale = d3.scaleLinear()
      .domain([0, coreValue.questions.length])
      .range([0, width]);

    const questionWidth = (
      width - LEFT_PADDING
    ) / coreValue.questions.length;

    // this scale can be used as an offset to the xQuestionsScale, to
    // determine the x offset within the question section for each point
    // based on the peer type
    const xPeerTypeScale = d3.scalePoint()
      .domain([
        SELF_TYPE,
        PEER_TYPE.MANAGER,
        PEER_TYPE.DIRECT_REPORT,
        PEER_TYPE.ADDITIONAL
      ])
      .range([0, questionWidth])
      .padding(0.5)

    // the y axis has ticks, tickSizeOuter means the ticks on the very
    // beginning and end of the scale
    const yAxis = d3.axisLeft(yScale).ticks(5).tickSize(10).tickSizeOuter(0);

    // the x axis has no ticks, we draw vertical dividing lines ourself
    const xAxis = d3.axisBottom(xQuestionsScale).ticks(0).tickSize(0);

    // render axes
    const yAxisSelection = d3.select(this.yAxisRef.current).call(yAxis);
    d3.select(this.xAxisRef.current).call(xAxis);

    // style main axis lines for both axes
    d3.selectAll([
      this.xAxisRef.current,
      this.yAxisRef.current
    ]).select('.domain')
      .attr('stroke', grayAccent)
      .attr('stroke-width', 3);

    // style yaxis tick lines
    yAxisSelection.selectAll('.tick')
        .select('line')
          .attr('stroke', grayAccent)
          .attr('stroke-width', 2)
          .attr('transform', 'translate(-1, 0)');

    // hide yaxis numeric tick labels
    yAxisSelection.selectAll('.tick')
      .select('text')
      .attr('display', 'none');

    return (
      <div className="row mt-5">
        <div className="col">

          <div className="row">
            <div className="col-1 p-0 text-right" style={styles.ratingLabelContainer}>
              {/* Here the d3 scale instance is used to position the
              labels outside of the SVG using CSS in a column next to
              the SVG plot. */}
              {RATING_LABELS.map((ratingLabel, i) => (
                  <PlotYLabel
                    key={`rating_label_${i}`}
                    top={yScale(ratingLabel.rating) - BOTTOM_PADDING - 2}
                    text={ratingLabel.text}
                  />
              ))}
            </div>

            <div className="col-11">
              <svg style={containerStyle} ref={this.containerRef}>
                {/* top-level group adds padding */}
                <g transform={`translate(${LEFT_PADDING},-${BOTTOM_PADDING})`}>

                  {/* x and y axes */}
                  <g ref={this.yAxisRef}></g>
                  <g
                    ref={this.xAxisRef}
                    transform={`translate(0,${height})`}
                  ></g>

                {/* vertical lines between question segments */}
                  <g>
                    {coreValue.questions.map((question, i) => (
                        <line
                          key={`question_dividing_line_${i}`}
                          x1={xQuestionsScale(i)}
                          y1={0}
                          x2={xQuestionsScale(i)}
                          y2={height}
                          stroke={grayAccent}
                          strokeWidth={1}
                        />
                    ))}
                  </g>

                  {/* data points, for each question.  First a single data
                  point bubble is rendered for the self rating, then bubbles
                  are rendered for each peer type. */}
                  {coreValue.questions.map((question, i) => (
                      <g key={`question_circles_${i}`}>
                        <PlotBubble
                          type={SELF_TYPE}
                          rating={question.selfAnswer.rating}
                          questionIndex={i}
                          xQuestionsScale={xQuestionsScale}
                          xPeerTypeScale={xPeerTypeScale}
                          yScale={yScale}
                        />
                        <PeerBubbles
                          xQuestionsScale={xQuestionsScale}
                          xPeerTypeScale={xPeerTypeScale}
                          yScale={yScale}
                          questionIndex={i}
                          question={question}
                        />
                      </g>
                  ))}
                </g>
              </svg>
            </div>
          </div>

          <div className="row">
            <div className="col-1"></div>
            <div
              className="col-11"
              style={Object.assign(styles.questionLabelContainer)}
            >
              {/* 
              Labels along the x-axis, again the d3 scales are used
              to position the DOM elements outside of the SVG.
              
              In left={}:
              - 10 is the padding to get Q1 appropriately 
              aligned a little bit to the right of the start of the 
              graph.
              - (i * 7) is due to a HTML -> PDF bug where we need to 
              space each Q2, Q3, etc out to keep them aligned. It
              will look off in local development but works in Prod.
              */}
              {coreValue.questions.map((question, i) => (
                  <PlotXLabel
                    key={`question_label_${i}`}
                    width={questionWidth}
                    left={xQuestionsScale(i) + LEFT_PADDING + 10 + (i * 7)}
                    index={i}
                    question={question}
                  />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default CoreValueQuestionsPlot;
