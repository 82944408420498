import React from 'react';
import { Formik, Form, FieldArray, Field } from 'formik';

import TalentFormSection from './TalentFormSection';
import FormTextInput from './FormTextInput';
import FormSubmitButton from './FormSubmitButton';
import { ReviewHeading3 } from '../../common/components/Headings';
import {
  createValidationSchema,
  createInitialValues,
  NUM_STORYWORDS
} from '../forms/personal';
import { REVIEW_TYPE } from '../../models';
import FormErrorChecker from '../../common/components/FormErrorChecker';


const getStoryWordsBlurbText = function (review) {
  switch (review.reviewType) {
    case REVIEW_TYPE.SELF_REVIEW:
      return (
        <div>
          <p>Enter the {NUM_STORYWORDS} words you would use to describe yourself. These should be the first 6 words that come to mind when you think about how you want others to experience you, similar to word association exercises. This is used to help you understand and align your aspirational brand to how you are actually experienced.</p>
          <p>Just a heads up, you can only enter one word per line so if it is a two-worded description, we recommend using a hyphen.</p>
        </div>
      )
    case REVIEW_TYPE.PEER_REVIEW:
      return (
        <div>
          <p>Enter the {NUM_STORYWORDS} words you would use to describe {review.selfReview.user.firstName}. These should be the first 6 words that come to mind when you think about your interactions with {review.selfReview.user.firstName}, similar to word association exercises. This is used to help {review.selfReview.user.firstName} understand and align their aspirational brand to how you actually experience them.</p>
          <p>Just a heads up, you can only enter one word per line so if it is a two-worded description, we recommend using a hyphen.</p>
        </div>
      )
    default:
      return '';
  }
}

const PersonalForm = ({ onSubmit, onBack, review, previousTalentAnswers, previousStoryWords, hideTalents }) => {
  const initialValues = createInitialValues(previousTalentAnswers, previousStoryWords, hideTalents);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createValidationSchema(hideTalents)}
      onSubmit={onSubmit}
      render={({ values, isSubmitting, errors, dirty }) => {
        const talentOptions = review.reviewCycle.talents.map(talent => (
          {
            value: talent.id,
            text: talent.name
          }
        ));

        return (
          <Form>
            {
              !hideTalents && (
                <FieldArray
                  name="talentAnswers"
                  render={arrayHelpers => {
                    const talentAnswers = arrayHelpers.form.values.talentAnswers;

                    return (
                      <div>
                        {values.talentAnswers.map((talentAnswer, index) => {
                          // remove talent from other dropdown if already chosen
                          let filteredTalentOptions = talentOptions;
                          if (talentAnswers) {
                            const firstAnswer = talentAnswers[0].talent;
                            const secondAnswer = talentAnswers[1].talent;

                            function removeTalentByAnswerIndex(answerIndex) {
                              return talentOptions.filter((option, optionIndex) => option.value !== answerIndex);
                            }

                            // first dropdown
                            if (index === 0 && secondAnswer !== 0) {
                              filteredTalentOptions = removeTalentByAnswerIndex(secondAnswer);
                              // second dropdown
                            } else if (index === 1 && firstAnswer !== 0) {
                              filteredTalentOptions = removeTalentByAnswerIndex(firstAnswer);
                            }
                          }

                          return (
                            <TalentFormSection
                              talentOptions={filteredTalentOptions}
                              index={index}
                              key={`ReviewTalentFormSection_${index}`}
                            />
                          );
                        })}
                      </div>
                    );
                  }}
                />
              )
            }
            <FieldArray
              name="storyWords"
              render={arrayHelpers => (
                <div>
                  <ReviewHeading3>Personal Brand</ReviewHeading3>
                  {getStoryWordsBlurbText(review)}

                  {values.storyWords.map((storyWord, index) => (
                    <Field
                      name={`storyWords[${index}]text`}
                      key={`storyWords[${index}]text`}
                      component={FormTextInput}
                      multiline={false}
                      label={`Word ${index + 1}`}
                    />
                  ))}
                </div>
              )}
            />
            <FormErrorChecker
              isSubmitting={isSubmitting}
              errors={errors}
            >
              {hasError => (
                <FormSubmitButton
                  hasError={hasError}
                  text={review.reviewType === REVIEW_TYPE.SELF_REVIEW ? 'Next' : 'Submit'}
                  onBack={onBack}
                  isDirty={dirty}
                />
              )}
            </FormErrorChecker>
          </Form>
        );
      }}
    />
  );
}

export default PersonalForm;
