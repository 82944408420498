import React from 'react';

import { grayAccent } from '../../colors';

class HorizontalDivider extends React.Component {
  render() {
    return (
      <div style={style}></div>
    );
  }
};

const style = {
  width: '100%',
  height: '2px',
  marginTop: '0.5em',
  backgroundColor: grayAccent
};

export default HorizontalDivider;
