import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import TextIconButton from '../TextIconButton';

function AddButton(props) {
  return (
    <TextIconButton
      iconComponent={AddIcon}
      colorName="primary"
      text="add another"
      {...props}
    />
  );
}

export default AddButton;
