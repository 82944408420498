import React from 'react';

import { familyHeavy } from '../../fonts';
import { SELF_COLOR } from '../../colors';
import { sizeXl } from '../fontSizes';

/**
 *  @class        InfoPageHeading
 *
 *  @classdesc    Pages with mostly words use this component as the main
 *  heading.
 **/
const InfoPageHeading = ({smallTopMargin=false, children}) => (
  <p style={
    Object.assign({}, style, (
        smallTopMargin ? smallTopMarginStyle : bigTopMarginStyle
    ))
  }>{children}</p>
);

const smallTopMarginStyle = {
  marginTop: '0.5in',
};

const bigTopMarginStyle = {
  marginTop: '2.0in',
};

const style = {
  fontFamily: familyHeavy,
  fontSize: sizeXl,
  color: SELF_COLOR,
};

export default InfoPageHeading;
