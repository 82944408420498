import React from 'react';
import InfoPage from './components/InfoPage';
import InfoPageHeading from './components/InfoPageHeading';
import InfoPageSubheading from './components/InfoPageSubheading';
import Paragraph from './components/Paragraph';

const BottomLinePage = ({...props}) => (
  <InfoPage {...props}>
    <InfoPageHeading>Consider your brand.</InfoPageHeading>
    <InfoPageSubheading>Brand is the enduring, relevant, and distinctive benefit people associate with you. It is unique to you and cannot be copied by others.</InfoPageSubheading>
    <Paragraph>
      People may be able to copy what you do, but how you are experienced can be a differentiator when working with others.
    </Paragraph>

    <Paragraph>
      The great thing about a successful brand is that it is timeless. Your relevant skills will likely change and evolve as other skills become outdated, but your brand should last forever if you are self-aware and always aligning how you want to be experienced with how you are actually being experienced.
    </Paragraph>

    <Paragraph heavy={true}>
      Quality, Reliability, and Consistency
    </Paragraph>

    <Paragraph>
      A quality brand should focus on a few relevant attributes rather than a long list of frivolous words. You should also be able to rely on your brand at all times, good or bad, because a reliable brand builds trust and confidence. Finally, a brand that is consistent will help you gain loyalty and allow you to show up how you want to on a regular basis, no matter the circumstances.
    </Paragraph>

    <Paragraph heavy={true}>
      Perception is Reality
    </Paragraph>

    <Paragraph>
      Our brand is something that we can influence, but not control. We control what we do and how we do it – those choices inform how others perceive us and shape our brand. Done by design, these choices can build a brand that is effective across relationships and situations.
    </Paragraph>
  </InfoPage>
);

export default BottomLinePage;
