import React from 'react';
import {Formik, Form, FieldArray} from 'formik';

import CoreValueFormSection from './CoreValueFormSection';
import FormSubmitButton from './FormSubmitButton';
import {
  createValidationSchema,
  createInitialValues
} from '../forms/corevalue';
import FormErrorChecker from '../../common/components/FormErrorChecker';
import {doArraysMatch} from '../../common/utils'



const CoreValueForm = ({coreValue, previousAnswers, orgStyles, onSubmit, onBack }) => {
  const formValidationSchema = createValidationSchema(coreValue);
  return (
    <Formik
      initialValues={previousAnswers.length !== 0 ? createInitialValues(coreValue, previousAnswers) : createInitialValues(coreValue)}
      // this indicates to Formik to re-render when initialValues change
      enableReinitialize={true}
      validationSchema={formValidationSchema}
      onSubmit={(values, { resetForm }) => {
        // this protects against the form attempting to re-render with the
        // previous initialValues for one render cycle before
        // componentDidUpdate where Formik will see they have changed and
        // re-render with the new initialValues
        resetForm({
          coreValueAnswers: []
        });
        onSubmit(formValidationSchema.cast(values));
      }}
      render={({ values, errors, isSubmitting, dirty }) => (
        <Form>
          <FieldArray
            name="coreValueAnswers"
            render={arrayHelpers =>
              {
                // Checks if initialValues are equal to values coming in via props
                // If no, render empty div to resolve render cycle
                // If yes, proceed as normal
                const initialQuestions = values.coreValueAnswers.map(value => value.question)
                const propQuestions = coreValue.questions.map(value => value.id)
                if (!doArraysMatch(initialQuestions, propQuestions)) {
                  return <div></div>;
                }
                return (
                  <div>
                    {values.coreValueAnswers.map((coreValueAnswer, i) => {
                      return (
                        <CoreValueFormSection
                          key={`ReviewCoreValueFormSection_question-${coreValue.questions[i].id}`}
                          question={coreValue.questions[i]}
                          index={i}
                          orgStyles={orgStyles}
                        />
                      )})}
                    </div>
                );
              }
            }
          />
          <FormErrorChecker
            isSubmitting={isSubmitting}
            errors={errors}
          >
            {hasError => (
              <FormSubmitButton
                hasError={hasError}
                text="Next"
                onBack={onBack}
                isDirty={dirty}
              />
            )}
          </FormErrorChecker>
        </Form>
      )}
    />
  )
};

export default CoreValueForm;
