export function getPeerName(hidePeerNames, peerReview) {
  if (hidePeerNames) {
    return 'Anonymous';
  } else {
    return `${peerReview.reviewer.firstName} ${peerReview.reviewer.lastName}`;
  }
};

export function serializePersonalBrandData(review) {
  const data = [];

  const getWordsFromStoryWordsObject = (storyWords) => {
    return storyWords.map(wordObject => wordObject.text);
  }

  data.push({
    id: review.id,
    stakeholderName: 'Self Score',
    words: getWordsFromStoryWordsObject(review.storyWords),
  });

  for (const peerReview of review.peerReviews) {
    data.push({
      id: peerReview.id,
      stakeholderName: getPeerName(review.reviewCycle.hidePeerNames, peerReview),
      words: getWordsFromStoryWordsObject(peerReview.storyWords),
    })
  }

  return data;
}
