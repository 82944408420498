import React from 'react';

import AddButtonSection from './AddButtonSection';
import StakeholderFieldGroup from './StakeholderFieldGroup';

import {
  EMPTY_PEER,
} from '../../forms/stakeholders';

const StakeholderFormSection = ({values, arrayHelpers, formParentName, peerTypeLabel, maxNumPeers, existingCount, alwaysShowRemove}) => (
  <div>
    {values[formParentName].map((peerAnswer, index) => (
        <StakeholderFieldGroup
          index={index}
          formParentName={formParentName}
          key={`StakeholderFieldGroup-${formParentName}-${index}`}
          onRemoveClick={() => arrayHelpers.remove(index)}
          alwaysShowRemove={alwaysShowRemove}
          peerTypeLabel={peerTypeLabel}
        />
    ))}
    <AddButtonSection
      onAddClick={() => arrayHelpers.push(EMPTY_PEER)}
      maxNumPeers={maxNumPeers}
      currentNumPeers={values[formParentName].length + existingCount}
    />
  </div>
);

export default StakeholderFormSection;
