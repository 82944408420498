import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {grayAccent} from '../../../colors';

const styles = {
  container: {
    marginLeft: '1em'
  },
  root: {
    color: grayAccent,
    '&$checked': {
      color: grayAccent,
    },
  },
  checked: {},
  label: {
    paddingTop: '5px'
  }
};

class NoReportsCheckbox extends React.Component {
  render() {
    const { classes, field } = this.props;

    let labelText = "I don't have any direct reports";
    if (field.name === 'noManagers') {
      labelText = "I don't have any managers";
    }

    return (
      <FormControlLabel
        classes={{
          root: classes.container,
          label: classes.label
        }}
        control={
          <Checkbox
            checked={field.value}
            onChange={field.onChange}
            name={field.name}
            value={field.name}
            classes={{
              root: classes.root,
              checked: classes.checked,
            }}
          />
        }
        label={labelText}
      />
    );
  }
}

export default withStyles(styles)(NoReportsCheckbox);
