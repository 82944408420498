import React from 'react';
import RemoveIcon from '@material-ui/icons/Remove';
import TextIconButton from '../TextIconButton';

function RemoveButton(props) {
  return (
    <TextIconButton
      aria-label="Delete"
      iconComponent={RemoveIcon}
      colorName="secondary"
      text="remove"
      {...props}
    />
  );
}


export default RemoveButton;
