import React from 'react';

import StakeholderStoryWordsList from './StakeholderStoryWordsList';
import HorizontalDivider from './HorizontalDivider';
import {ReportHeading} from './Headings';

import {
  PEER_TYPE
} from '../../models';

const PEER_TYPE_LABELS = {
  [PEER_TYPE.MANAGER]: 'Manager',
  [PEER_TYPE.DIRECT_REPORT]: 'Direct Reports',
  [PEER_TYPE.ADDITIONAL]: 'Other Key Stakeholders'
};

const StakeholderStoryWordsSection = ({
  peerType,
  storyWordsByPeerType,
  styles
}) => (
  <div style={styles}>
    <div className="row">
      <div className="col">
        <ReportHeading sizeLg={true}>
          {PEER_TYPE_LABELS[peerType]}
        </ReportHeading>
      </div>
    </div>

    <div className="row" style={{ marginBottom: '0.875rem' }}>
      <div className="col">
        <HorizontalDivider />
      </div>
    </div>

    <StakeholderStoryWordsList
      storyWords={storyWordsByPeerType[peerType]}
    />
  </div>
);

export default StakeholderStoryWordsSection;
