import React from 'react';
import { StyleSheet } from 'aphrodite';
import Button from '@material-ui/core/Button/index'

import ReviewMainColumn from './components/ReviewMainColumn';
import {
  ReviewHeading1,
  ReviewHeading2,
} from '../common/components/Headings';
import StakeholderForm from './components/StakeholderForm/StakeholderForm';


/**
 *  @class        ReviewStakeholders
 *
 *  @classdesc    Stakeholders review page, only shows up on self-review.
 *  Heavily leverages Formik's FieldArray to add / remove stakeholders of
 *  each type.
 **/
const ReviewStakeholders = ({review, onSubmit, orgStyles, onBack, currentPageNum}) => {
  const { peerAnswers } = review;
  
  return (
    <ReviewMainColumn review={review} currentPageNum={currentPageNum}>
      <ReviewHeading1 additionalStyles={[orgStyles.secondaryColorText]}>
        Stakeholder Submission
      </ReviewHeading1>

      <ReviewHeading2 additionalStyles={[styles.subheaderText]}>
        Ask for Feedback
      </ReviewHeading2>

      <p>Who are the people you want feedback from? We’ll start with your manager (think, the person who gives you your yearly evaluation or the person you report to directly)</p>
      <p>Next, we'll need you to enter the people that report directly to you. Feel free to check the box if you have no direct reports. Bear in mind that you'll need at least three stakeholders to have enough feedback for a report of value.</p>
      <p>After your direct reports are entered, add some additional stakeholders such as peers or co-workers and that will do it! We encourage you to enter no more than 15 stakeholders in order to keep your report relevant and actionable.</p>

      <StakeholderForm onSubmit={onSubmit} onBack={onBack} previousPeerAnswers={peerAnswers} />

    </ReviewMainColumn>
  );
};

const styles = StyleSheet.create({
  subheaderText: {
    marginBottom: '40px',
  },
});

export default ReviewStakeholders;
