import React from 'react';
import moment from 'moment';
import { StyleSheet } from 'aphrodite';

import GoalsFormSelf from './components/GoalsFormSelf';
import GoalsFormPeer from './components/GoalsFormPeer';
import ReviewMainColumn from './components/ReviewMainColumn';
import {
  ReviewHeading1,
  ReviewHeading2,
  ReviewHeading3,
  ReviewHeading4
} from '../common/components/Headings';
import WelcomeVideo from './components/WelcomeVideo';
import ReviewerFirstName from '../common/components/ReviewerFirstName';

import { REVIEW_TYPE } from '../models';

function videoDuration (seconds) {
  let durationString = moment.duration(seconds, 'seconds').humanize();

  const endsInS = durationString[durationString.length - 1] === 's';

  if (endsInS) {
    durationString = durationString.slice(0, durationString.length - 1);
  }

  return durationString;
}

const getBelowVideoText = function (review) {
  switch (review.reviewType) {
    case REVIEW_TYPE.SELF_REVIEW:
      return (
        <div>
          <p>Blank Page believes when leaders operate with curiosity, compassion, creativity, courage, and commitment, we not only accomplish great work together, we build trust and confidence in our relationships that will make "how" we work as meaningful as "what" we accomplished. With that in mind let's get started with your self-evaluation.</p>
          <p>Please reach out to your Blank Page coach if you have any questions.</p>
        </div>
      );
    case REVIEW_TYPE.PEER_REVIEW:
      return (
        <div>
          <p>Blank Page believes when people operate with curiosity, compassion, creativity, courage, and commitment, we not only accomplish great work together, we build trust and confidence in our relationships that will make "how" we work as meaningful as "what" we accomplished. With that in mind let's get started with providing your feedback.</p>
          <p>Please reach out to help@guideinsights.com if you have any questions.</p>
        </div>
      );
    default:
      return '';
  }
};

const getOrgPerspectiveText = function (review) {
  switch (review.reviewType) {
    case REVIEW_TYPE.SELF_REVIEW:
      return (
        <div>
          <p>The first part of the feedback experience is all about cultural norms and their connected behaviors. Cultural norms are the shared beliefs and values that guide our thinking and behaviors. Some refer to this as our "how". For each norm, there are a set of preferred behaviors that when successfully incorporated into our work, shape the culture of the organization and how we are experienced by others.</p>
          <p>On each page in this section, you'll see a short explanation for a specific cultural norm, followed by behavior statements that you self-evaluate and receive feedback on.</p>
          <p>You will be asked to indicate to what extent you believe yourself to be meeting, missing, or exceeding your expectations for the given behavior. This is completely based on your interactions with others and what you expect from yourself.</p>
          <p>At the bottom of this page, after the overview of the cultural norms, you'll be asked to identify a specific goal for this feedback cycle. This goal is important so that your stakeholders can give feedback that will be even more personal, relevant and actionable.</p>
        </div>
      );
    case REVIEW_TYPE.PEER_REVIEW:
      return (
        <div>
          <p>The first part of the feedback experience is all about cultural norms and their connected behaviors. Cultural norms are the shared beliefs and values that guide our thinking and behaviors. Some refer to this as our "how". For each norm, there are a set of preferred behaviors that when successfully incorporated into our work, shape the culture of the organization and how we are experienced by others.</p>
          <p>On each page in this section, you'll see a short explanation for a specific cultural norm, followed by behavior statements that you will give feedback on.</p>
          <p>You will be asked to indicate to what extent you believe {review.selfReview.user.firstName} is <b>meeting</b>, <b>missing</b>, or <b>exceeding</b> your expectations for the given behavior. This is completely based on your relationship with {review.selfReview.user.firstName} and what you expect from it.</p>
          <p>{review.selfReview.user.firstName} has identified a specific goal for this feedback cycle. Be sure to familiarize yourself with this goal (the target in the top left corner) before you begin so that your feedback will be even more personal, relevant and actionable.</p>
        </div>
      );
    default:
      return '';
  }
}


/**
 *  @class        ReviewWelcome
 *
 *  @classdesc    Welcome page.  Displays video and either a goals form or
 *  a display of the reviewee's goals.
 **/
const ReviewWelcome = ({review, onSubmit, orgStyles, currentPageNum}) => {
  return (
    <ReviewMainColumn review={review} currentPageNum={currentPageNum}>
      <ReviewHeading1 additionalStyles={[orgStyles.secondaryColorText]}>
        Hello, <ReviewerFirstName review={review} />.
      </ReviewHeading1>

      <ReviewHeading2 additionalStyles={[styles.welcomeText]}>Welcome to Guide insights!</ReviewHeading2>

      <ReviewHeading4>Watch this {videoDuration(review.reviewCycle.welcomeVideoDuration)} video to get started.</ReviewHeading4>
      <WelcomeVideo reviewCycle={review.reviewCycle} />
      {getBelowVideoText(review)}


      <ReviewHeading3>Cultural Norms & Behaviors</ReviewHeading3>
      <p>{getOrgPerspectiveText(review)}</p>

      {review.reviewCycle.coreValues.map((coreValue, i) => {
        return (
          <div key={coreValue.id}>
            <ReviewHeading4>{coreValue.name}</ReviewHeading4>
            <p>{coreValue.description}</p>
          </div>
        )
      })}


      {
        review.reviewType === REVIEW_TYPE.SELF_REVIEW &&
          <GoalsFormSelf
            review={review}
            onSubmit={onSubmit}
          />
      }
      {
        review.reviewType === REVIEW_TYPE.PEER_REVIEW &&
          <GoalsFormPeer
            review={review}
            onSubmit={onSubmit}
          />
      }
    </ReviewMainColumn>
  );
};

const styles = StyleSheet.create({
  welcomeText: {
    whiteSpace: 'nowrap',
  },
});

export default ReviewWelcome;
