import React from 'react';
import { StyleSheet } from 'aphrodite';

import ReviewMainColumn from './components/ReviewMainColumn';
import {
  ReviewHeading1,
  ReviewHeading2,
  ReviewHeading3,
} from '../common/components/Headings';
import PersonalForm from './components/PersonalForm';
import { REVIEW_TYPE } from '../models';


const getH1HeadingText = function (review) {
  switch (review.reviewType) {
    case REVIEW_TYPE.SELF_REVIEW:
      return 'Your Brand';
    case REVIEW_TYPE.PEER_REVIEW:
      return 'Personal Brand';
    default:
      return '';
  }
}


const getH2HeadingText = function (review) {
  switch (review.reviewType) {
    case REVIEW_TYPE.SELF_REVIEW:
      return 'Understanding How You Show Up';
    case REVIEW_TYPE.PEER_REVIEW:
      return 'Understanding How I Show Up';
    default:
      return '';
  }
}

const getSubHeadingBlurbText = function (review, hideTalents) {
  switch (review.reviewType) {
    case REVIEW_TYPE.SELF_REVIEW:
      return (
        hideTalents ?
        <div>
          <p>Your leadership brand illustrates what you deliver and how you deliver it and should be an authentic representation of what you aspire to. It differentiates you based on your unique value and allows people to know what to expect from you.</p>
          <p>This quick section helps you understand if there is a difference between how you want to show up and how others think you show up (your aspirational brand versus your actual brand). To do that, all we need from you right now are the six words that best describe how you want to show up as a leader.</p>
        </div>
        :
        <div>
          <p>In this section, you will be asked to choose two leadership talents that you believe would provide the most return on investment if you focused your development in these areas.</p>
          <p>A talent is a differentiator in the delivery of outstanding results. When we identify a talent to develop, it should be something that challenges us to have a greater impact in our work and demonstrate our potential for growth.</p>
          <p>The last thing you will do is articulate your personal brand in a simple and concise way using just six words.</p>
        </div>
      );
    case REVIEW_TYPE.PEER_REVIEW:
      return (
        hideTalents ?
        <div>
          <p>Leadership brand illustrates what you deliver and how you deliver it and should be an authentic representation of what you aspire to. It differentiates you based on your unique value and allows people to know what to expect from you. When people think about your brand, your unique talents should immediately come to mind.</p>
          <p>This quick section helps me understand if there is a difference between how I want to show up and how you think I show up (my aspirational brand versus my actual brand). To do that, it would be really helpful to know the six words that best describe how I show up as a leader in your mind. </p>
        </div>
        :
        <div>
          <p>In this section, you will be asked to choose two leadership talents that you believe would provide the most return on investment if {review.selfReview.user.firstName} focused their development in these areas.</p>
          <p>A talent is a differentiator in the delivery of outstanding results. When we identify a talent to develop, it should be something that challenges us to have a greater impact in our work and demonstrate our potential for growth.</p>
          <p>The last thing you will do is describe your experience with {review.selfReview.user.firstName} in a simple and concise way using just six words.</p>
        </div>
      );
    default:
      return '';
  }
};

const getTalentsSubHeadingBlurbText = function (review) {
  switch (review.reviewType) {
    case REVIEW_TYPE.SELF_REVIEW:
      return (
        <div>
          <p>Select two talents that you believe are important focus areas for your development. Don't forget, your selection does not imply a deficit or strength, but simply offers a perspective on where you believe development would have the most impact.</p>
        </div>
      )
    case REVIEW_TYPE.PEER_REVIEW:
      return (
        <div>
          <p>Select two talents that you believe are important focus areas for {review.selfReview.user.firstName}'s development. Don't forget, your selection does not imply a deficit or strength, but simply offers a perspective on where you believe development would have the most impact.</p>
          <p>Remember to select two different talents.</p>
        </div>
      )
    default:
      return '';
  }
}

/**
 *  @class        ReviewPersonal
 *
 *  @classdesc    Personal section of review allowing selection of two talents
 *  and a six word story.
 **/
const ReviewPersonal = ({review, onSubmit, orgStyles, onBack, currentPageNum, hideTalents}) => {
  const reviewH1Heading = hideTalents ? getH1HeadingText(review) : 'Leadership Talents';
  const reviewH2Heading = hideTalents ? getH2HeadingText(review) : 'A Direction for Development';
  // Returns an array containing the previously defined story words, ordered by id
  const previousStoryWords = review.storyWords
		.map((word) => word)
    .sort((a, b) => a.id - b.id);

  return (
    <ReviewMainColumn review={review} currentPageNum={currentPageNum}>
      <ReviewHeading1 additionalStyles={[orgStyles.secondaryColorText]}>
        {reviewH1Heading}
      </ReviewHeading1>

      <ReviewHeading2 additionalStyles={[styles.talentsSubTitle]}>{reviewH2Heading}</ReviewHeading2>
      {getSubHeadingBlurbText(review, hideTalents)}

      {
        !hideTalents && (
          <>
            <ReviewHeading3>Talents</ReviewHeading3>
            {getTalentsSubHeadingBlurbText(review)}
          </>
        )
      }

      <PersonalForm
        review={review}
        onSubmit={onSubmit}
        onBack={onBack}
        previousTalentAnswers={review.talentAnswers}
        previousStoryWords={previousStoryWords}
        hideTalents={hideTalents}
      />

    </ReviewMainColumn>
  );
};

const styles = StyleSheet.create({
  talentsSubTitle: {
    marginBottom: '40px',
  },
});

export default ReviewPersonal;
