import React from 'react';
import { StyleSheet, css } from 'aphrodite';

import MainColumn from '../../common/components/MainColumn';
import {
  ReviewHeading1,
  ReviewHeading2,
} from '../../common/components/Headings';
import ReviewerFirstName from '../../common/components/ReviewerFirstName';
import CoreValueQuestionsAndResponses from './CoreValueQuestionsAndResponses';
import PersonalBrandSection from './PersonalBrandSection';
import { serializePersonalBrandData } from './utils';

/**
 *  @class        AllDataMain
 *
 *  @classdesc    Main column for all data page.  Renders questions and
 *  answers for all CoreValues.
 **/
const AllDataMain = ({ report, orgStyles }) => {
  const personalBrandData = serializePersonalBrandData(report.review);
  return (
    <MainColumn>
      <ReviewHeading1 additionalStyles={[orgStyles.secondaryColorText]}>
        Hello, <ReviewerFirstName review={report.review} />.
    </ReviewHeading1>
      <ReviewHeading2 additionalStyles={[styles.subheadingText]}>
        Here is all of your data.
    </ReviewHeading2>

    <p>
      This is a question by question look at how every stakeholder responded to each question, including any qualitative comments that they may have added.
    </p>

      {report.review.reviewCycle.coreValues.map(coreValue => (
        <CoreValueQuestionsAndResponses
          key={coreValue.id}
          coreValue={coreValue}
          review={report.review}
          orgStyles={orgStyles}
        />
      ))}
      <div className={css(styles.divider)} />
      <PersonalBrandSection
        data={personalBrandData}
        orgStyles={orgStyles}
      />

    </MainColumn>
  );
}

const styles = StyleSheet.create({
  subheadingText: {
    marginBottom: '40px',
  },
  divider: {
    width: '100%',
    height: 1,
    maxWidth: 744,
    backgroundColor: 'rgba(112, 112, 112, 0.87)',
  },
});

export default AllDataMain;
