import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  rightIcon: {
    marginLeft: '0.5em',
    marginTop: '-3px',
  },
  button: {
    textTransform: 'uppercase',
    fontSize: '15px',
    lineHeight: '1.05',
    letterSpacing: '1.3px',
    paddingTop: '13px',
    paddingBottom: '11px',
    paddingRight: '23px',
    borderColor: '#0000001e'
  },
  buttonTopSpacing: {
    marginTop: '2em'
  }
});


const TextIconButton = ({
  classes,
  onClick,
  disabled,
  colorName,
  text,
  iconComponent
}) => {

  const TheIconComponent = iconComponent;
  return (
    <Button
      variant="outlined"
      color={colorName}
      className={classes.button}
      onClick={onClick}
      disabled={disabled}
    >
    {text}
    <TheIconComponent className={classes.rightIcon} />
    </Button>
  );
};

export default withStyles(styles)(TextIconButton);
