import React from 'react';

import InfoPage from './components/InfoPage';
import InfoPageHeading from './components/InfoPageHeading';
import InfoPageSubheading from './components/InfoPageSubheading';
import Paragraph from './components/Paragraph';
import { ReportHeading } from './components/Headings';

import {
  SELF_COLOR
} from '../colors';

import {
  familyHeavy,
} from '../fonts';
import { sizeLg } from './fontSizes';

const styles = {
  storyWord: {
    fontFamily: familyHeavy,
    fontSize: sizeLg,
    color: SELF_COLOR,
    marginBottom: '1em'
  }
};

const YourBrandInWordsPage = ({review, ...props}) => (
  <InfoPage {...props}>
    <div className="row">
      <div className="col-12">
        <InfoPageHeading>Your Brand in<br />Six Words</InfoPageHeading>
      </div>
      <div className="col-7">
        <InfoPageSubheading>These are the six words you used to describe yourself in your self-evaluation.</InfoPageSubheading>
        <ReportHeading marginBottom="3em" color={SELF_COLOR}>Questions to Answer</ReportHeading>
        <ReportHeading marginBottom="4em">Is this what I am today or what I aspire to be?</ReportHeading>
        <ReportHeading marginBottom="4em">If this is my story, does it differentiate me?</ReportHeading>
        <ReportHeading marginBottom="1em">Does this perspective of myself match the perspective my stakeholders have?</ReportHeading>
        <Paragraph accent={true} italic={true}>You can see how your stakeholders described you in six words on the next page.</Paragraph>
      </div>
      <div className="col-4 ml-5">
        {review.storyWords.map(storyWord => (
            <div
              key={storyWord.id}
              style={styles.storyWord}
            >{storyWord.text}</div>
        ))}
      </div>
    </div>
  </InfoPage>
);

export default YourBrandInWordsPage;
