import React from 'react';

import Page from './Page';
import Header from './Header';

/**
 *  @class        InfoPage
 *
 *  @classdesc    Layout of pages that are a big heading and text.
 **/
const InfoPage = ({children, ...props}) => (
  <Page>
    <Header {...props} />
    <div className="row">
      <div className="col-11">
        {children}
      </div>
    </div>
  </Page>
);

export default InfoPage;
