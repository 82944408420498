import React from 'react';

const bottomTextContainerStyle = {
  textAlign: 'center',
  width: '100%',
  position: 'relative',
};

const bottomTextStyle = {
  bottom: '2em',
  width: '100%',
  position: 'absolute'
};

const BackCoverInsidePage = () => (
  <div style={bottomTextContainerStyle}>
    <p style={bottomTextStyle}>&copy; {(new Date()).getFullYear()} Blank Page. All rights reserved.</p>
  </div>
);

export default BackCoverInsidePage;
