import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

const styles = theme => ({
  container: {
    position: 'absolute',
    right: '11px',
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  icon: {
    color: 'white',
    height: '24px',
    width: '24px',
  }
});

/**
 *  @class        NavigationCheckIcon
 *
 *  @classdesc    Icon displayed in navbar next to each section name, uses the
 *  `reviewSection` to determine if the section should be displayed as
 *  "checked" or "unchecked".
 **/
function NavigationCheckIcon(props) {
  const { classes, reviewSection } = props;

  const IconComponent = reviewSection.finished ? CheckCircleOutlineIcon : RadioButtonUncheckedIcon;
  return (
    <div className={classes.container}>
      <IconComponent classes={{root: classes.icon}} />
    </div>
  );
}

NavigationCheckIcon.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NavigationCheckIcon);
