import React from 'react';

import { familyBook, familyHeavy } from '../../fonts';
import { sizeSm, sizeLg } from '../fontSizes';
import { grayAccent } from '../../colors';

export const ReportHeading = ({
  children,
  sizeLg=false,
  accentGray=false,
  color=false,
  marginBottom=0
}) => (
  <p style={
    Object.assign(
      {},
      styles.heading,
      sizeLg ? styles.largeHeading : {},
      accentGray ? styles.gray : {},
      color ? {color: color} : {},
      {marginBottom}
    )
  }>{children}</p>
);

export const ReportSubHeading = ({children}) => (
  <p style={styles.subheading}>{children}</p>
)

const styles = {
  heading: {
    color: "#000000",
    fontFamily: familyHeavy,
  },
  subheading: {
    color: "#000000",
    fontFamily: familyBook,
    fontSize: sizeSm,
    marginTop: '0.5em'
  },
  largeHeading: {
    fontSize: sizeLg
  },
  gray: {
    color: grayAccent
  }
};
