import React from 'react';
import * as d3 from 'd3';
import * as _ from 'lodash';

import {
  barChartColor, PEER_TYPE_COLORS, SELF_COLOR
} from '../../colors';

import { InlineScoreNumber } from './ScoreNumbers';

const numLines = 2;

const styles = {
  rowContainer: {
    marginBottom: '1.5em',
  },
  barContainer: {
    height: '0.2in',
    position: 'relative',
    verticalAlign: 'middle'
  },
  barLabel: {
    // this truncates the label if more than 2 lines using CSS.  Currently
    // does not add an ellipsis or anything, which is a bit strange but this
    // is a corner case.
    display: 'block',
    wordWrap: 'break-word',
    overflow: 'hidden',
    maxHeight: `${1.1 * numLines}em`,
  },
  barValue: {
    position: 'absolute',
    height: '100%',
    backgroundColor: barChartColor
  },
  barInnerLine: {
    position: 'absolute',
    height: '20%',
    width: '100%',
    top: '40%',
    backgroundColor: barChartColor
  },
  giverColorIndicator: {
    width: '0.875rem',
    height: '0.875rem',
    borderRadius: '0.4375rem',
    marginRight: '1.125rem',
    float: 'left',
  },
}

const HorizontalBarChart = ({
  data,
  maxValue=null,
  integers=false,
  // by default, bars and labels are equal width
  barsNumColumns=6,
  isGiverChart=false,
}) => {
  if (maxValue === null) {
    maxValue = d3.max(data, _.property('value'));
  }

  return (
    <div>
      {data.map((point, i) => {
        let giverColor;
        if (isGiverChart) {
          giverColor = PEER_TYPE_COLORS[point.key] || SELF_COLOR;
        }

        return (
          <div key={i} style={styles.rowContainer} className="row">
            { /* label */ }
            <div className={`col-${12 - barsNumColumns}`}>
              {isGiverChart && (
                <div
                  style={{
                    ...styles.giverColorIndicator,
                    backgroundColor: giverColor,
                  }}
                />
              )}
              <span style={styles.barLabel}>
                {point.label}
              </span>
            </div>

            <div className={`col-${barsNumColumns}`}>
              <div className="row">
                { /* horizontal bar line */ }
                <div className="col-10">
                  <div style={styles.barContainer}>
                    <div
                      style={{
                        ...styles.barInnerLine,
                        ...isGiverChart ? { backgroundColor: giverColor } : {},
                      }}></div>
                    <div style={Object.assign(
                      { width: `${(point.value / maxValue) * 100.0}%`},
                      styles.barValue,
                      isGiverChart ? { backgroundColor: giverColor } : {},
                    )}></div>
                  </div>
                </div>
                { /* numeric value */ }
                <div className="col-2">
                  <InlineScoreNumber value={point.value} />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  )
};

export default HorizontalBarChart;
