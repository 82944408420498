import React from 'react';
import InfoPage from './components/InfoPage';

import InfoPageHeading from './components/InfoPageHeading';
import InfoPageSubheading from './components/InfoPageSubheading';
import Paragraph from './components/Paragraph';

const SettingSuccessPage = ({ ...props }) => (
  <InfoPage {...props}>

    <InfoPageHeading>Setting Yourself<br />
      Up For Success</InfoPageHeading>
    <InfoPageSubheading>When you start to work with your data, consider this proven approach. </InfoPageSubheading>
    <Paragraph>Go through this report once and glance at all of your summarized data.</Paragraph>
    <Paragraph>Acknowledge and identify any emotional reaction you are having. What do you need to do to react the way you would like to?  Remember only you can unlock your commitment to learn.</Paragraph>
    <Paragraph>When you are ready, go through your macro data again and focus on the “big picture.” Are there trends or major themes?  Are there differences and/or similarities between stakeholder groups?  Does the feedback support your own self-assessment?</Paragraph>
    <Paragraph>Highlight areas of interest so you can explore them in more detail when you get your raw data.</Paragraph>
    <Paragraph heavy={true}>Before you begin, ask yourself what biases you are bringing to your report and what assumptions you are operating with. Jot them down, set them aside, and come back to test against them after reading through your data.</Paragraph>
  </InfoPage>
);

export default SettingSuccessPage;
