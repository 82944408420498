import React from 'react';

import Page from './components/Page';
import Header from './components/Header';
import StakeholderStoryWordsSection from './components/StakeholderStoryWordsSection';

import {
  PEER_TYPE
} from '../models';

const styles = {
  directReportStoryWordsContainer: {
    marginTop: '0.5in'
  }
};

const StakeholdersPerspectiveContinuedPage = ({
  storyWordsByPeerType,
  ...props
}) => (
  <Page>
    <Header {...props} />

    <StakeholderStoryWordsSection
      storyWordsByPeerType={storyWordsByPeerType}
      peerType={PEER_TYPE.DIRECT_REPORT}
      styles={styles.directReportStoryWordsContainer}
    />
  </Page>
);

export default StakeholdersPerspectiveContinuedPage;
