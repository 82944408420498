import React from 'react';
import { StyleSheet, css } from 'aphrodite';

import { grayAccent, turquoiseAccent, errorRed } from '../../colors';
import { getReviewIsComplete } from '../../models';
import { familyHeavy } from '../../fonts';

const PeerReviewStatusTableIndicator = ({peerReview, reviewCycle}) => {
  const isComplete = getReviewIsComplete(peerReview, reviewCycle);

  if (isComplete) {
    return <span className={css(styles.complete)}>Submitted</span>;
  } else {
    return <span className={css(styles.incomplete)}>Waiting</span>;
  }
};

const PeerReviewStatusTable = ({peerReviews, reviewCycle}) => (
  <div className={`row ${css(styles.container)}`}>
    <div className="col-8">
      {peerReviews.map(peerReview => (
          <div
            className={`row ${css(styles.statusRow)}`}
            key={peerReview.id}
          >
            <div className={`col-6 ${css(styles.peerName)}`}>
              <span>{peerReview.reviewer.firstName}&nbsp;</span>
              <span>{peerReview.reviewer.lastName}</span>
            </div>
            <div className="col-6">
              <PeerReviewStatusTableIndicator
                peerReview={peerReview}
                reviewCycle={reviewCycle}
              />
            </div>
          </div>
      ))}
    </div>
  </div>
);

const styles = StyleSheet.create({
  statusRow: {
    padding: '0.5em 0 0.5em 0',
    borderBottom: `1px solid ${grayAccent}`
  },
  peerName: {
    fontFamily: familyHeavy
  },
  complete: {
    color: turquoiseAccent
  },
  incomplete: {
    color: errorRed
  },
  container: {
    marginTop: '3em',
    marginBottom: '80px',
  }
})

export default PeerReviewStatusTable;
