import React from 'react';
import InfoPage from './components/InfoPage';

import InfoPageHeading from './components/InfoPageHeading';
import InfoPageSubheading from './components/InfoPageSubheading';
import Paragraph from './components/Paragraph';

const CulturalNormsPage = ({...props}) => (
  <InfoPage {...props}>

    <InfoPageHeading smallTopMargin={true}>Cultural Norms</InfoPageHeading>
    <InfoPageSubheading>Whether you call them beliefs, values, or something else, cultural norms help us make decisions consistently across an organization and show up in productive ways. </InfoPageSubheading>
    <Paragraph>In this section you will see summarized feedback data from each category of stakeholder around these norms. You and your stakeholders provided responses based on behaviors that demonstrate a commitment to the corresponding areas.</Paragraph>
    <Paragraph>Your data provides you with an opportunity to examine whether you are meeting, missing, or exceeding expectations against these behaviors, providing insight into what degree you show up in alignment with the cultural norms.</Paragraph>
    <Paragraph>With a deeper understanding of specific behaviors and expectations, you can develop a plan to seek further information, clarify disconnects, and develop habits to align expectations and behaviors. This work allows you to be thoughtful and deliberate in how you are experienced by others.</Paragraph>
    <Paragraph heavy={true}>What to Watch For</Paragraph>
    <Paragraph>What strengths are you seeing?
      <br />
    What opportunities are you seeing?
    <br />
    What are you seeing that you weren’t expecting?
  </Paragraph>
  <Paragraph heavy={true}>The Scale</Paragraph>
  <Paragraph>Each of the behaviors is assessed on a scale of 1-5:
    <br />
    5 = Exceeding Expectations
    <br />
    3 = Meeting Expectations
    <br />
    1 = Missing Expectations
    <br />
  </Paragraph>
  <Paragraph>Responses of 2 or 4 represent reactions that fall between the descriptors and could represent either trends or inconsistencies. These are great opportunities to seek further understanding from your stakeholders.</Paragraph>
  <Paragraph>Remember, expectations are just that - comparing what happens to what is expected. Expectations are created in a variety of ways – understanding them, aligning them, and delivering on them is the work of all leaders.</Paragraph>
  </InfoPage>
);

export default CulturalNormsPage;
