import React, { Component } from 'react';
import './App.css';
import { Route } from 'react-router-dom';

import Report from './report/Report';
import Review from './review/Review';
import AllData from './alldata/AllData';
import Status from './status/Status';


class App extends Component {
  render() {
    return (
      <div>
        <Route
          path='/report/:reportUUID'
          component={Report}
        />
        <Route
          path='/status/:reportUUID'
          component={Status}
        />
        <Route
          path='/review/:reviewUUID'
          component={Review}
        />
        <Route
          path='/alldata/:reportUUID'
          component={AllData}
        />
      </div>
    );
  }
}

export default App;
