/**
 *  @file     stakeholders.js
 *
 *  @desc     Form validation logic and helper text for the stakeholders
 *  review page.
 **/
import * as Yup from 'yup';

import {
  FIELD_REQUIRED_TEXT
} from './common';
import { PEER_TYPE } from '../../models';

export const MAX_NUM_MANAGERS = 2;
export const MAX_NUM_DIRECTREPORTS = 12;
export const MAX_ADDITIONAL = 6;
export const MAX_ADDITIONAL_WHEN_NO_DIRECT = 10;

/**
 *  Prototype shape to validate any kind of stakeholder (in API this is a
 *  PeerAnswer)
 **/
const peerAnswerValidationShape = {
  firstName: Yup.string().trim().required(FIELD_REQUIRED_TEXT).max(256),
  lastName: Yup.string().trim().required(FIELD_REQUIRED_TEXT).max(256),
  email: Yup.string().trim().required(FIELD_REQUIRED_TEXT).email(
    "Please enter a valid email address"
  ),
  title: Yup.string().trim().notRequired().max(256),
  phone: Yup.string().trim().notRequired().matches(
    /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/, {
      message: "Please enter a valid phone number",
      excludeEmptyString: true
    }
  )
};

export const createValidationSchema = function (existingManagerCount, existingReportCount, existingStakeholderCount) {
let categoryMinimum = 1;
if ((existingManagerCount + existingReportCount + existingStakeholderCount) > 0) {
  categoryMinimum = 0;
}

  return Yup.object().shape({
    // checkbox to indicate no managers
    noManagers: Yup.boolean().default(false),
    managers: Yup.array().when(
      'noManagers', {
        is: true,
        then: Yup.array().min(0).notRequired(),
        otherwise: Yup.array().min(categoryMinimum).max(MAX_NUM_MANAGERS - existingManagerCount).of(
          Yup.object().shape(
            Object.assign({
              peerType: Yup.string().min(2).max(2).default(PEER_TYPE.MANAGER)
            }, peerAnswerValidationShape)
          )
        ),
      }
    ),
    // checkbox to indicate no direct reports
    noDirectReports: Yup.boolean().default(false),
    // the amount of direct reports allowed depends on the checkbox
    directReports: Yup.array().when(
      'noDirectReports', {
        is: true,
        then: Yup.array().min(0).notRequired(),
        otherwise: Yup.array().min(categoryMinimum).max(MAX_NUM_DIRECTREPORTS - existingReportCount).of(
          Yup.object().shape(
            Object.assign({
              peerType: Yup.string().min(2).max(2).default(
                PEER_TYPE.DIRECT_REPORT
              )
            }, peerAnswerValidationShape)
          )
    )}),
    additionalStakeholders: Yup.array().when(
      // the amount of additional stakeholders allowed also depends on the
      // checkbox
      'noDirectReports', {
        is: true,
        then: Yup.array().min(0).max(
          MAX_ADDITIONAL_WHEN_NO_DIRECT - existingStakeholderCount,
          `Can add up to ${MAX_ADDITIONAL_WHEN_NO_DIRECT} additional stakeholders`
        ),
        otherwise: Yup.array().min(0).max(
          MAX_ADDITIONAL - existingStakeholderCount,
          `Can add up to ${MAX_ADDITIONAL} additional stakeholders`
        )
      }
    ).of(
      Yup.object().shape(
        Object.assign({
          peerType: Yup.string().min(2).max(2).default(PEER_TYPE.ADDITIONAL)
        }, peerAnswerValidationShape)
      )
    )
  });
};

/**
 *  Prototype of an empty peer form field.  `peerType` is explicitly set to
 *  `undefined` so Yup will fill in the default value (depending on where
 *  in the form it is used).
 **/
export const EMPTY_PEER = {
  firstName: "",
  lastName: "",
  email: "",
  title: "",
  phone: "",
  peerType: undefined
};

export const createInitialValues = function (hasExistingValues) {
  if (hasExistingValues) {
    return {
      managers: [],
      noDirectReports: false,
      directReports: [],
      additionalStakeholders: [],
    }
  } else {
    return {
      managers: [
        Object.assign({}, EMPTY_PEER)
      ],
      noDirectReports: false,
      directReports: [
        Object.assign({}, EMPTY_PEER)
      ],
      additionalStakeholders: [
        Object.assign({}, EMPTY_PEER)
      ],
    };
  }
};
