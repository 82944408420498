import React from 'react';
import { StyleSheet } from 'aphrodite';
import Button from '@material-ui/core/Button'

import ReviewMainColumn from './components/ReviewMainColumn';
import {
  ReviewHeading1,
  ReviewHeading2,
  ReviewHeading3
} from '../common/components/Headings';
import CoreValueForm from './components/CoreValueForm';

import { REVIEW_TYPE } from '../models';


const getExpectationsStatement = function (review) {
  switch (review.reviewType) {
    case REVIEW_TYPE.SELF_REVIEW:
      return <p>Read each statement and indicate how well you are meeting your own expectations.</p>
    case REVIEW_TYPE.PEER_REVIEW:
      return <p>Read each statement and indicate how well {review.selfReview.user.firstName} is meeting your expectations.</p>      
    default:
      return '';
  }
}


/**
 *  @class        ReviewCoreValue
 *
 *  @classdesc    Render all pages of the review in the "Organization" section
 *  displaying a CoreValueQuestion for each CoreValue and the associated form.
 **/

const ReviewCoreValue = ({review, onSubmit, orgStyles, onBack, currentPageNum}) => {
  const coreValueIndex = currentPageNum - 1;
  const coreValues = review.reviewCycle.coreValues;
  const coreValue = coreValues[coreValueIndex];
  const questionIds = review.reviewCycle.coreValues[coreValueIndex].questions.map((item) => item.id);
  const previousAnswers = review.coreValueAnswers.filter((answer) => (questionIds.includes(answer.question) ? answer : false));
  
  if (!coreValue) {
    return <ReviewMainColumn></ReviewMainColumn>;
  }
  return (
    <ReviewMainColumn review={review} currentPageNum={currentPageNum}>
      <ReviewHeading1 additionalStyles={[orgStyles.secondaryColorText]}>
        Cultural Norms & Behaviors
      </ReviewHeading1>
      <ReviewHeading2 additionalStyles={[styles.coreValueHeader]}>
        {coreValue.name}
      </ReviewHeading2>
      <p>{coreValue.description}</p>

      <ReviewHeading3 additionalStyles={[styles.coreValueHeader]}>Behaviors</ReviewHeading3>

      <p>The following behaviors have been identified as desirable for the above cultural norm.</p> 

      {getExpectationsStatement(review)}

      <CoreValueForm
        coreValue={coreValue}
        previousAnswers={previousAnswers}
        onSubmit={onSubmit}
        onBack={onBack}
        orgStyles={orgStyles}
      />


    </ReviewMainColumn>
  )
};

const styles = StyleSheet.create({
  coreValueHeader: {
    marginBottom: '40px',
  },
});


export default ReviewCoreValue;
