import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { StyleSheet, css } from 'aphrodite';

import {
  ReviewHeading4
} from '../../common/components/Headings';
import { errorRed } from '../../colors';

const styles = theme => ({
  container: {
    width: '100%',
    display: 'block',
    textAlign: 'right',
    marginTop: '140px',
    marginBottom: '60px'
  },
  button: {
    paddingLeft: '46px',
    paddingBottom: '12px',
    paddingTop: '16px',
    paddingRight: '28px',
    borderRadius: 'unset'
  },
  backButton: {
    paddingRight: '46px',
    paddingBottom: '12px',
    paddingTop: '16px',
    paddingLeft: '28px',
    borderRadius: 'unset',
    backgroundColor: 'white',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
  },  
  errorText: {
    color: errorRed,
    fontSize: '15px',
    marginRight: '16px',
  },
  label: {
    fontSize: '15px',
    lineHeight: '1.05',
    letterSpacing: '1.3px',
  },
  rightIcon: {
    marginLeft: '22px',
    fontSize: '22px',
    marginTop: '-4px'  // To vertically align the arrow with the text
  },
  leftIcon: {
    marginRight: '22px',
    fontSize: '22px',
    marginTop: '-4px'  // To vertically align the arrow with the text
  },  
  submitRowBetween: {
    display: 'flex',    
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  submitRowEnd: {
    display: 'flex',    
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  paper: {
    position: 'absolute',
    width: '640px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    textAlign: 'center',
  },
  modalText: {
    fontSize: 17,
    lineHeight: 1.44,
    letterSpacing: '0.5px',
    color: 'black',
    fontWeight: 500,
  },
  problemIcon: {
    fontSize: 54,
    color: errorRed,
  },
  buttonContainer: {
    marginTop: 60,
  },
  confirmButton: {
    fontSize: 12,
    width: 210,
    height: 48,
    color: 'black',
    backgroundColor: 'rgba(98, 2, 238, 0)',
    marginRight: 21,
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  cancelButton: {
    "&:hover": {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    fontSize: 12,
    width: 210,
    height: 48,
    color: 'white',
    backgroundColor: 'black',
  }
});

const top = 50;
const left = 50;
const modalStyle = {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
};

// FIXME: Using MUI theme styles clashes with passing in aphrodite stylesheet to ReviewHeading
const stylesheetStyles = StyleSheet.create({
  modalHeading: {
    color: errorRed,
    marginTop: 20,
    marginBottom: 16,
  }
});

/**
 *  @class        FormSubmitButton
 *
 *  @classdesc    Submit button used on all Review pages to submit the form
 *  and move to the next page.
 **/
class FormSubmitButton extends React.Component {
  state = {
    modalOpen: false,
  };  

  handleClose = () => {
    this.setState({ modalOpen: false }); 
  }

  render() {
    const {
      classes,
      hasError,
      text,
      showBack,
      onBack,
      isDirty,
    } = this.props;

    return (
      <div className={classes.container}>
        <div className={showBack ? classes.submitRowBetween : classes.submitRowEnd}>
          {showBack ? (<Button 
            variant="contained"
            className={classes.backButton}
            type="button"
            classes={{
              label: classes.label
            }}
            onClick={() => {
              if (isDirty) {
                this.setState({ modalOpen: true });
              } else {
                onBack();
              }            
            }}
          >
            <KeyboardArrowLeftIcon
              className={classes.leftIcon}
            >
              back
            </KeyboardArrowLeftIcon>
            Back
          </Button>) : ''}

          {hasError && (
            <div className={classes.errorText}>
              Please fix the errors above
            </div>
          )}

          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            type="submit"
            classes={{
              label: classes.label
            }}
          >
            {text}
            <KeyboardArrowRightIcon
              className={classes.rightIcon}
            >
              send
            </KeyboardArrowRightIcon>
          </Button>
        </div>
        <Modal
          aria-labelledby="back-warning"
          aria-describedby="back-warning-message"
          open={this.state.modalOpen}
          onClose={this.cancelBack}
        >
          <div style={modalStyle} className={classes.paper}>
            <div>
              <ReportProblemOutlinedIcon className={classes.problemIcon} />
            </div>
            <ReviewHeading4 additionalStyles={[stylesheetStyles.modalHeading]}>
              Are you sure you want to go back?
            </ReviewHeading4>
            <p className={classes.modalText}>
              You have unsaved work on this page. <br/>
              To save these answers, finish filling out the page and then click the Next button. 
              Once you do that, you can go back without losing work!             
            </p>
            <div className={classes.buttonContainer}>
              <Button className={classes.confirmButton} onClick={onBack}>yes, go to previous page</Button>
              <Button className={classes.cancelButton} onClick={this.handleClose}>stay on this page</Button>
            </div>
          </div>
        </Modal>      
      </div>
    );
  }
}

FormSubmitButton.defaultProps = {
  hasError: false,
  showBack: true,
};

FormSubmitButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FormSubmitButton);
