import React from 'react';
import {ReportHeading} from '../Headings';

import { InlineScoreNumber } from '../ScoreNumbers';

const lineHeightEm = 1.2;
const numLines = 6;

const styles = {
  container: {
    position: 'absolute',
    height: '100%'
  },
  questionText: {
    // this truncates the label if more than 2 lines using CSS.  Currently
    // does not add an ellipsis or anything, which is a bit strange but this
    // is a corner case.
    display: 'block',
    wordWrap: 'break-word',
    overflow: 'hidden',
    maxHeight: `${lineHeightEm * numLines}em`,
    lineHeight: `${lineHeightEm}em`
  }
};

const PlotXLabel = ({width, left, index, question}) => (
  <div
    style={{width, left, ...styles.container}}>
    <ReportHeading sizeLg={true}>
      Q{index+1} <InlineScoreNumber value={question.peerRatingAverage} />
    </ReportHeading>
    <p style={styles.questionText}>{question.text}</p>
  </div>
);

export default PlotXLabel;
