import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { ReviewHeading3, ReviewHeading4 } from '../../common/components/Headings';

const PersonalBrandSection = ({ data, orgStyles }) => {
  return (
    <div className={css(styles.PersonalBrandSection)}>
      <ReviewHeading3 additionalStyles={[styles.header]}>
        Personal Brand
      </ReviewHeading3>
      <ReviewHeading4 additionalStyles={[orgStyles.secondaryColorText, styles.subHeader]}>
        Here are the six words that each person used to describe you.
      </ReviewHeading4>
      {data.map(review => {
        // do not show a reviewer when they did not select words / complete a review
        if (review.words.length > 0) {
          return (
            <div
              className={css(styles.stakeholderReview)}
              key={review.id}
            >
              <ReviewHeading4 additionalStyles={[styles.stakeholderName]}>
                {review.stakeholderName}
              </ReviewHeading4>
              {review.words.map((word, index) => (
                <p key={index} className={css(styles.wordText)}>
                  {word}
                </p>
              ))}
            </div>
          );
        }
      })}
    </div>
  );
};

export default PersonalBrandSection;

const styles = StyleSheet.create({
  PersonalBrandSection: {
    paddingTop: 80,
  },
  //
  header: {
    marginTop: 0,
    marginBottom: 80,
  },
  subHeader: {
    maxWidth: 480,
    marginTop: 0,
    marginBottom: 84,
  },
  stakeholderName: {
    marginTop: 0,
    marginBottom: 10,
  },
  stakeholderReview: {
    marginBottom: 40,
  },
  wordText: {
    fontSize: 17,
    lineHeight: 1.7,
    margin: 0,
  },
});
