import React from 'react';
import { Field } from 'formik';

import {
  ReviewHeading4
} from '../../common/components/Headings';
import RatingFormField from './RatingFormField';
import HiddenCommentTextField from './HiddenCommentTextField/HiddenCommentTextField';
import {
  coreValueDescriptionCharLimit
} from '../forms/corevalue';

/**
 *  Displays the CoreValueQuestion and input for the rating and why text field
 **/
const CoreValueFormSection = ({orgStyles, question, index, ...props}) => (
  <div>
    <ReviewHeading4>Q{index + 1}. {question.text}</ReviewHeading4>

    <Field
      name={`coreValueAnswers[${index}]rating`}
      component={RatingFormField}
      orgStyles={orgStyles}
      {...props}
    />

    <Field
      name={`coreValueAnswers[${index}]description`}
      component={HiddenCommentTextField}
      label="Why did you choose that rating? (optional)"
      charLimit={coreValueDescriptionCharLimit}
      {...props}
    />

  </div>
);

export default CoreValueFormSection;
