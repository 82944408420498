import React from 'react';

import { familyHeavy } from '../../../fonts';
import { PEER_TYPE_COLORS, SELF_COLOR } from '../../../colors';
import { SELF_TYPE } from '../../../models';

const CIRCLE_RADIUS = 10;

const styles = {
  circleText: {
    fontFamily: familyHeavy,
    fill: 'white'
  }
};

/**
 *  @class        PlotBubble
 *
 *  @classdesc    A single point in the questions plot, a colored circle with
 *  a number inside.
 **/
const PlotBubble = ({
  /* PeerType or SELF_TYPE to indicate a self rating bubble */
  type,
  /* The actual rating value */
  rating,
  /* Quantity of ratings, number displayed in the bubble */
  number=null,
  /* which question (multiple questions are on the same plot) */
  questionIndex,
  /* d3 scales for x and y (see CoreValueQuestionsPlot) */
  xQuestionsScale,
  xPeerTypeScale,
  yScale
}) => {
  let color, x, y;
  if (type === SELF_TYPE) {
    color = SELF_COLOR;
  } else {
    color = PEER_TYPE_COLORS[type];
  }
  x = xQuestionsScale(questionIndex) + xPeerTypeScale(type);
  y = yScale(rating);
  return (
    <g
      transform={`translate(${x}, ${y})`}
    >
      <circle r={CIRCLE_RADIUS} fill={color} />
      {
        // only show bubble number on peer bubbles, not self
        type !== SELF_TYPE && (
          <text
            style={styles.circleText}
            dy={0.5 * CIRCLE_RADIUS - 1}
            textAnchor="middle"
            alignmentBaseline="middle"
          >
            {number}
          </text>
        )
      }
    </g>
  )
};

export default PlotBubble;
