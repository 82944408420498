import React from 'react';

import {PEER_TYPE_LIST} from '../../../models';
import PlotBubble from './PlotBubble';

/**
 *  @class        PeerBubbles
 *
 *  @classdesc    Renders the columns of peer bubbles for a single question
 *  on a plot.
 **/
const PeerBubbles = ({
  xQuestionsScale,
  xPeerTypeScale,
  yScale,
  questionIndex,
  question
}) => (
  <g>
  {PEER_TYPE_LIST.map(peerType => {
    const answerRatingCounts = (
    question.peerAnswerRatingCountsByPeerType[
      peerType
    ]
    );

    return Object.keys(answerRatingCounts).map(rating => (
        <PlotBubble
          key={`question_${questionIndex}_${peerType}_${rating}`}
          type={peerType}
          rating={rating}
          questionIndex={questionIndex}
          number={answerRatingCounts[rating]}
          xQuestionsScale={xQuestionsScale}
          xPeerTypeScale={xPeerTypeScale}
          yScale={yScale}
        />
    ));
  })}
</g>
);

export default PeerBubbles;
