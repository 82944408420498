import React from 'react';

import HorizontalBarChart from './components/HorizontalBarChart';
import Page from './components/Page';
import Header from './components/Header';
import HorizontalDivider from './components/HorizontalDivider';
import { ReportHeading, ReportSubHeading } from './components/Headings';
import VerticalDivider from './components/VerticalDivider';
import { InsightsScoreNumber } from './components/ScoreNumbers';

const styles = {
  barChartRow: {
    marginTop: '3em',
    marginBottom: '3em',
    // this makes sure the bar chart row takes up most of the vertical space
    height: '30%'
  },
  bottomRow: {
    marginTop: '2em',
  },
  scoreNumberContainer: {
    height: '100%'
  }
};

/**
 *  @class        CoreValueAveragePage
 *
 *  @classdesc    This page displays the averages of all core values and the
 *  big "insights score".  Entire layout is implemented here because it is
 *  unique.
 **/

const CoreValueAveragePage = ({
  coreValues,
  insightsScore,
  ...props
}) => (
  <Page>
    <Header {...props} />
    <div className="row">
      <div className="col-4">
        <ReportHeading>
          Average Cultural Norm<br />
          Scores Across All Feedback
        </ReportHeading>
        <ReportSubHeading>Does not include your self score</ReportSubHeading>
      </div>
    </div>

    { /* This flex stuff centers the bar chart vertically */ }
    <div
      className="d-flex flex-column justify-content-center"
      style={styles.barChartRow}
    >
      <HorizontalBarChart
        data={coreValues.map(coreValue => ({
            label: coreValue.name,
            value: coreValue.peerRatingAverage
        }))}
        maxValue={5}
        barsNumColumns={7}
      />
    </div>

    <div className="row">
      <div className="col">
        <HorizontalDivider />
      </div>
    </div>

    <div className="row" style={styles.bottomRow}>
      <div className="col-4">
        <ReportHeading marginBottom={'2em'}>
          Guide Insights Score
        </ReportHeading>
        <p>This is an average of every rating you received from feedback givers. </p>

        <p>This score is simply a starting point for healthy conversations about how well you are exemplifying the five behaviors we have declared as important differentiators for leadership.</p>

        <p>Inspiring leaders are not only committed to the behaviors that are important, but they also hold themselves accountable to being consistent and believable role models.</p>
      </div>
      <div className="col-1 pl-4">
        <VerticalDivider />
      </div>
      <div className="col-7">
        <div className="row">
          <div className="col">
            <ReportHeading>Your Guide Insights Score</ReportHeading>
            <ReportSubHeading>Does not include your self score</ReportSubHeading>
          </div>
        </div>
        <div
          className="d-flex flex-column justify-content-center text-center"
          style={styles.scoreNumberContainer}
        >
          <InsightsScoreNumber value={insightsScore} />
        </div>
      </div>
    </div>

  </Page>
);

export default CoreValueAveragePage;
