/**
 *  @file   welcome.js
 *
 *  @desc   Form validation logic and field helper text for welcome page.
 **/

import * as Yup from 'yup';

import {
  FIELD_REQUIRED_TEXT,
  makeCharLimitText,
} from './common';

export const goalCharLimit = 256;
export const goalWhyCharLimit = 256;

export const createValidationSchema = function () {
  return Yup.object().shape({
    goal: Yup.string()
      .max(
        goalCharLimit,
        makeCharLimitText(goalCharLimit)
      )
      .required(FIELD_REQUIRED_TEXT),
    goalWhy: Yup.string()
      .max(
        goalWhyCharLimit,
        makeCharLimitText(goalWhyCharLimit)
      )
      .required(FIELD_REQUIRED_TEXT)
  });
};

/**
 * 
 * @param {string} [goal] 
 * @param {string} [goalWhy] 
 */
export const createInitialValues = function(goal = '', goalWhy = '') {
		return { goal, goalWhy };	
};
