import React from 'react';
import InfoPage from './components/InfoPage';
import InfoPageHeading from './components/InfoPageHeading';
import InfoPageSubheading from './components/InfoPageSubheading';
import Paragraph from './components/Paragraph';

const subheadingTextOptions = {
  talentsHiddenSubheading: "You've got a lot of data around expectations relating to your behaviors and where you might focus your development. As you work to integrate the qualitative and quantitative insights, consider these questions to move towards action.",
  defaultSubheading: "You've gotten a lot of data around expectations as they relate to your behaviors and your talents and where you might focus your development. As you work to integrate the qualitative and quantitative insights, consider these questions to move towards action."
}

const TakingActionsFeedbackPage = ({...props}) => {
  const { hideTalent } = props;
  const { talentsHiddenSubheading, defaultSubheading } = subheadingTextOptions;
  return (
    <InfoPage {...props}>
      <InfoPageHeading>Taking Action<br />
      on Feedback</InfoPageHeading>
      <InfoPageSubheading>
        {hideTalent ? talentsHiddenSubheading : defaultSubheading}
      </InfoPageSubheading>
      <Paragraph heavy={true} accent={true}>
        Prioritize Your Actions
      </Paragraph>
      <Paragraph heavy={true}>
        What 1-3 behaviors or mindsets are essential for you to prioritize in your development?
      </Paragraph>
      {!hideTalent &&
        <Paragraph heavy={true}>
          Which two talents are you going to focus on?
        </Paragraph>
      }
      <Paragraph heavy={true} accent={true}>
        Execution Requires a Reality Check
      </Paragraph>
      <Paragraph heavy={true}>
        Why are these important to you?
      </Paragraph>
      <Paragraph heavy={true}>
        What supporting factors will enhance your ability to make a change?
      </Paragraph>
      <Paragraph heavy={true}>
        What might get in the way? How can you mitigate them?
      </Paragraph>
      <Paragraph heavy={true}>
        What resource(s) do you need to be successful?
      </Paragraph>
    </InfoPage>
)};

export default TakingActionsFeedbackPage;
