import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';

import {
  ReviewHeading4
} from '../../common/components/Headings';
import { familyMedium } from '../../fonts';
import { REVIEW_TYPE, getGoalAnswer } from '../../models';
import { transparentBlack } from '../../colors';

const top = 50;
const left = 50;
const modalStyle = {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
};

const muiStyles = theme => ({
  paper: {
    position: 'absolute',
    width: '640px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
});

const getGoalHeadingText = function (review) {
  switch (review.reviewType) {
    case REVIEW_TYPE.SELF_REVIEW:
      return `Your goal for this evaluation`

    case REVIEW_TYPE.PEER_REVIEW:
      return `${review.user.firstName}’s goal for this evaluation`

    default:
      return ``;
  }
};

const getGoalWhyHeadingText = function (review) {
  switch (review.reviewType) {
    case REVIEW_TYPE.SELF_REVIEW:
      return 'Why this is your goal';
    case REVIEW_TYPE.PEER_REVIEW:
      return 'Why this is their goal';
    default:
      return '';
  }
};

/**
 *  @class        GoalModal
 *
 *  @classdesc    Button that looks like a bullseye, when clicked brings up
 *  modal.  Leveraging the Modal component from material-ui.
 **/
class GoalModal extends React.Component {
  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, review } = this.props;

    // welcome page, goal has not yet been entered
    if (review.numCompletedPages === 0) {
      return <div></div>;
    } else {
      const goalAnswer = getGoalAnswer(review);
      return (
        <div>
          <Button className={css(styles.goalButton)} onClick={this.handleOpen}>
            <img
              alt="open modal"
              src={`${process.env.PUBLIC_URL}/assets/icons/goal_bullseye.svg`}
            />
            <span className={css([styles.goalText, styles.goalHelperText])}>view goal</span>
          </Button>
          <Modal
            aria-labelledby="goals"
            aria-describedby="goals-for-reviewer"
            open={this.state.open}
            onClose={this.handleClose}
          >
            <div style={modalStyle} className={classes.paper}>
              <div className={css(styles.goalIconHeading)}>
                <img
                  alt="the goal"
                  src={`${process.env.PUBLIC_URL}/assets/icons/goal_bullseye.svg`}
                />
              </div>
              <ReviewHeading4 additionalStyles={[styles.goalHeading]}>
                {getGoalHeadingText(review)}
              </ReviewHeading4>
              <p className={css(styles.goalText)}>{goalAnswer.goal}</p>
              <ReviewHeading4 additionalStyles={[styles.goalHeading]}>
                {getGoalWhyHeadingText(review)}
              </ReviewHeading4>
              <p className={css(styles.goalText)}>{goalAnswer.goalWhy}</p>
              <div className="text-center">
                <Button color="primary" onClick={this.handleClose}>ok</Button>
              </div>
            </div>
          </Modal>
        </div>
      );
    }
  }
}

const styles = StyleSheet.create({
  goalButton: {
    display: 'flex',
    alignItems: 'center',
  },
  goalText: {
    fontFamily: familyMedium
  },
  goalHeading: {
    fontFamily: familyMedium,
    marginTop: '32px',
    marginBottom: '16px',
    fontWeight: 'normal'
  },
  goalIconHeading: {
    textAlign: 'center',
  },
  goalHelperText: {
    textTransform: 'uppercase',
    marginLeft: 10,
    color: transparentBlack,
    marginTop: 6,
    fontSize: 12,
  },
});

export default withStyles(muiStyles)(GoalModal);
