import React from 'react';
import { getIn } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { offBlack } from '../../colors';
import { sizeSm } from '../../fonts';

const styles = theme => ({
  textField: {
    width: '100%',
  },
  label: {
    fontSize: sizeSm,
    color: offBlack,
    position: 'relative',
    marginBottom: '1em'
  },
  labelFocused: {
    //color: `${offBlack} !important`
  },
  helperText: {
    fontSize: sizeSm,
    color: offBlack
  },
  helperTextFocused: {
    
  }
});

/**
 *  @class        FormTextInput
 *
 *  @classdesc    Component used for every text input on every form.
 **/
class FormTextInput extends React.Component {
  render() {
    const { classes, charLimit, field, form } = this.props;
    const multiline = this.props.multiline === undefined ? true : this.props.multiline;
    const touched = getIn(form.touched, field.name);
    const errors = getIn(form.errors, field.name);

    let helperText;
    if (touched && errors) {
      helperText = errors;
    } else {
      helperText = '';
    }

    if (charLimit) {
      if(field.value) {
        helperText = `${charLimit - field.value.length} characters remaining`;
      } else {
        helperText = `${charLimit} characters remaining`;
      }
    }

    return <TextField
      label={this.props.label}
      multiline={multiline}
      rows="4"
      placeholder={this.props.placeholder || "Aa"}
      className={classes.textField}
      margin="normal"
      InputLabelProps={{
        shrink: false,
        FormLabelClasses: {
          root: classes.label,
          focused: classes.labelFocused
        }
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.helperText
        }
      }}
      helperText={helperText}
      name={field.name}
      value={field.value.text ? field.value.text : field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      error={Boolean(touched && errors)}
      type={this.props.type}
    />;
  }
};

export default withStyles(styles)(FormTextInput);
