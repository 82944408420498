import React from 'react';
import moment from 'moment';
import { StyleSheet, css } from 'aphrodite';
import Button from '@material-ui/core/Button'

import ReviewMainColumn from './components/ReviewMainColumn';
import {
  ReviewHeading1,
  ReviewHeading4
} from '../common/components/Headings';
import { familyMedium } from '../fonts';
import { lightGrayText, lightGrayAccent, darkGrayText } from '../colors';
import { REVIEW_TYPE } from '../models';
import { getOrgStyles } from '../styles';

const getSubHeadingText = (reviewType) => {
  switch (reviewType) {
    case REVIEW_TYPE.PEER_REVIEW:
      return 'Your feedback is complete.';
    case REVIEW_TYPE.SELF_REVIEW:
      return 'Your self evaluation is complete.';
    default:
      throw new Error(`No subheading text for reviewType ${reviewType}`);
  }
}

const getCopyText = (review) => {
  switch (review.reviewType) {
    case REVIEW_TYPE.SELF_REVIEW:
      return (      
        <p>It looks like you’ve already submitted your feedback. If you need help or have any questions about this evaluation, email us at:</p> 
      );
    case REVIEW_TYPE.PEER_REVIEW:
      return (
        <p>Nice job! It looks like you've already given {review.selfReview.user.firstName} your perspective.</p> 
      );
    default:
      throw new Error(`No subheading text for reviewType ${review.reviewType}`);
  }
}

/**
 *  @class        ReviewFinished
 *
 *  @classdesc    Displayed when revisiting the review link after completing
 *  it already.
 **/
const ReviewFinished = ({review, onBack, onBackToBeginning}) => {
  const orgStyles = getOrgStyles(review.reviewCycle.organization);

  const endDate = moment(review.reviewCycle.endTimeIso);
  const reviewEnded = moment().isAfter(endDate);

  const atMaxReviewers = review.peerAnswers.length >= 20;

  let selfButtonText = 'Add More Stakeholders';
  let peerButtonText = 'Change Your Feedback';
  if (reviewEnded) {
    selfButtonText = 'Your Feedback Cycle Has Ended';
    peerButtonText = 'Your Feedback Cycle Has Ended';
  } else if (atMaxReviewers) {
    selfButtonText = 'Stakeholder Limit Reached';
  }

  return (
    <ReviewMainColumn review={review}>
      <div className={"row " + css(styles.container)}>
        <div className="col d-flex align-items-center justify-content-center">
          <img
            src={`${process.env.PUBLIC_URL}/assets/icons/review-finished.svg`}
            alt="finished"
          />
        </div>
        <div className="col">
          <ReviewHeading1
            additionalStyles={[
              styles.thanksHeading,
              orgStyles.secondaryColorText
            ]}
          >
            Finished!
          </ReviewHeading1>

          <ReviewHeading4 additionalStyles={[styles.thanksSubHeading]}>
            {getSubHeadingText(review.reviewType)}
          </ReviewHeading4>

          {getCopyText(review)}

          <p className={css(styles.helpEmail)}>
            <span>
              help@bpstudio.com
              <span className={css(styles.helpEmailUnderline)}></span>
            </span>
          </p>
          {
            review.reviewType === REVIEW_TYPE.SELF_REVIEW ? 
              <Button
                variant="outlined"
                color="primary"
                className={css(styles.button)}
                onClick={onBack}
                disabled={reviewEnded || atMaxReviewers}
              >
                {selfButtonText}
              </Button> : ''
          }
          {
            review.reviewType === REVIEW_TYPE.PEER_REVIEW ? 
              <Button
                variant="outlined"
                color="primary"
                className={css(styles.button)}
                onClick={onBackToBeginning}
                disabled={reviewEnded}
              >
                {peerButtonText}
              </Button> : ''
          }          
          { review.reviewType === REVIEW_TYPE.SELF_REVIEW && !reviewEnded ?
            <Button
              className={css(styles.changeEvaluationButton)}
              onClick={onBackToBeginning}
            >
              Change Your Evaluation
            </Button> : ''
          }
        </div>
      </div>
    </ReviewMainColumn>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: '2em'
  },
  thanksHeading: {
    marginBottom: '0.1em',
    marginTop: 0,
    color: darkGrayText
  },
  thanksSubHeading: {
    marginTop: 0,
    fontFamily: familyMedium,
    fontWeight: 'normal'
  },
  helpEmail: {
    color: lightGrayText,
    display: 'inline-block',
  },
  helpEmailUnderline: {
    borderBottom: `1px solid ${lightGrayAccent}`,
    display: 'inline-block',
    width: '100%'
  },
  button: {
    textTransform: 'uppercase',
    fontSize: '15px',
    lineHeight: '1.05',
    letterSpacing: '1.3px',
    paddingTop: '13px',
    paddingBottom: '11px',
    paddingRight: '23px',
    borderColor: '#0000001e',
    marginTop: '50px',
  },  
  changeEvaluationButton: {
    marginTop: '25px',
    color: '#293037',
    borderBottom: '2px solid #293037',
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    borderRadius: 0,
    marginLeft: '37px',
  }
});

export default ReviewFinished;
