import React from 'react';
import axios from 'axios';
import * as _ from 'lodash';
import * as d3 from 'd3';
import { MuiThemeProvider  } from '@material-ui/core/styles';

import './Report.scss';

import { getMuiTheme } from '../styles';

import CoverPage from './CoverPage';
import WelcomePage from './WelcomePage';
import LetsGetStartedPage from './LetsGetStartedPage';
import WhyFeedbackPage from './WhyFeedbackPage';
import SettingSuccessPage from './SettingSuccessPage';
import HowToThinkPage from './HowToThinkPage';
import CulturalNormsPage from './CulturalNormsPage';
import CoreValueAveragePage from './CoreValueAveragePage';
import MappingYourPerceptionsPage from './MappingYourPerceptionsPage';
import DevelopingLeadershipTalentsPage from './DevelopingLeadershipTalentsPage';
import TakingActionsFeedbackPage from './TakingActionsFeedbackPage';
import BottomLinePage from './BottomLinePage';
import DefineOrOthersWillPage from './DefineOrOthersWillPage';
import NextStepsPage from './NextStepsPage';
import FeedbackGiverCategoryPage from './FeedbackGiverCategoryPage';
import ThankYouPage from './ThankYouPage';
import BackCoverInsidePage from './BackCoverInsidePage';
import PerceptionsMapPage from './PerceptionsMapPage';
import CoreValuePage from './CoreValuePage';
import TalentsChartPage from './TalentsChartPage';
import YourChosenTalentsPage from './YourChosenTalentsPage';
import YourBrandInWordsPage from './YourBrandInWordsPage';
import StakeholdersPerspectivePage from './StakeholdersPerspectivePage';
import StakeholdersPerspectiveContinuedPage from './StakeholdersPerspectiveContinuedPage';

import {
  familyBook
} from '../fonts';

import { PEER_TYPE_LIST, PEER_TYPE } from '../models';

const API_URI = process.env.REACT_APP_API_URI;

const getRating = _.property('rating');

/**
 *  @class        Report
 *
 *  @classdesc    Top-level component for rendering a report, intended to be
 *  rendered with DocRaptor as a .pdf
 **/
class Report extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      review: {
        talentAnswers: [],
        storyWords: []
      },
      coreValues: [],
      talents: [],
      user: {
        firstName: "",
        lastName: ""
      },
      organization: {
        name: ""
      },
      insightsScore: 0.0,
      loaded: false,
      hideTalents: false,
      storyWordsByPeerType: {
        [PEER_TYPE.MANAGER]: [],
        [PEER_TYPE.DIRECT_REPORT]: [],
        [PEER_TYPE.ADDITIONAL]: []
      }
    };

    window.docraptorJavaScriptFinished = () => this.state.loaded;

  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.coreValues !== this.state.coreValues) {
      setTimeout(() => this.setState({loaded: true}), 250);
    }
  }
  prepareChartData (report) {
    const review = report.review;
    const peerReviews = review.peerReviews;
    const peerReviewsByPeerType = _.groupBy(peerReviews, 'peerAnswer.peerType');
    const peerReviewIdsByPeerType = {};
    PEER_TYPE_LIST.forEach(peerType => {
      if (peerReviewsByPeerType.hasOwnProperty(peerType)) {
        peerReviewIdsByPeerType[peerType] = peerReviewsByPeerType[peerType].map(
          _.property('id')
        );
      } else {
        peerReviewIdsByPeerType[peerType] = [];
      }
    });

    // Helper Functions
    // ------------------------------------------------------------------------
    function createInsightsScore(answers) {
      return answers.reduce(((sum, answer) =>
        sum + answer.rating
      ), 0.0) / answers.length;
    }

    function getCoreValueAnswersFromPeerReviews(peerReviewsList) {
      return peerReviewsList.reduce((list, peerReview) => (
        list.concat(peerReview.coreValueAnswers)
      ), []);
    }

    // Core Values
    // ------------------------------------------------------------------------
    // answers to every CoreValueQuestion by every peer
    const allPeerCoreValueAnswers = getCoreValueAnswersFromPeerReviews(peerReviews);
    // average rating for all core values
    const insightsScore = createInsightsScore(allPeerCoreValueAnswers);

    // for all core values
    const coreValues = review.reviewCycle.coreValues.map(coreValue => {

      // list of all question ids in this coreValue for aggregations
      const coreValueQuestionIds = coreValue.questions.map(q => q.id);
      // test to determine if a CoreValueAnswer is for one of our the
      // CoreValueQuestions for this CoreValue
      const answerIsForCoreValue = (ans) => (
        _.includes(coreValueQuestionIds, ans.question)
      );

      // answers to every question for this core value from peers
      const coreValuePeerAnswers = allPeerCoreValueAnswers.filter(
        answerIsForCoreValue
      );

      // answers to every question for this core value from self
      const coreValueSelfAnswers = review.coreValueAnswers.filter(
        answerIsForCoreValue
      );

      // for all questions in this core value
      const coreValueQuestions = coreValue.questions.map(question => {
        // test to determine if an answer is for this question
        const answerIsForQuestion = ans => ans.question === question.id;

        // answers to this question by all peers
        const peerAnswers = allPeerCoreValueAnswers.filter(answerIsForQuestion);

        // answers to this question by peer type
        const peerAnswersByPeerType = {};
        PEER_TYPE_LIST.forEach(peerType => {
          peerAnswersByPeerType[peerType] = peerAnswers.filter(ans => (
              _.includes(peerReviewIdsByPeerType[peerType], ans.review)
          ));
        });

        // answer to this question by self (assuming only one)
        const selfAnswer = review.coreValueAnswers.find(answerIsForQuestion);

        // average ratings for this question from all peers
        const peerRatingAverage = d3.mean(peerAnswers, getRating);

        // for each peer type, aggregate rating counts
        const peerAnswerRatingCountsByPeerType = {};
        PEER_TYPE_LIST.forEach(peerType => {
          peerAnswerRatingCountsByPeerType[peerType] = _.countBy(
            peerAnswersByPeerType[peerType],
            'rating'
          );
        });

        // add properties into the CoreValueQuestion
        return Object.assign(question, {
          peerAnswers,
          selfAnswer,
          peerRatingAverage,
          peerAnswersByPeerType,
          peerAnswerRatingCountsByPeerType
        })
      });

      // average ratings for this core value from peers
      const peerRatingAverage = d3.mean(coreValuePeerAnswers, getRating);

      // average rating for this core value from self
      const selfRatingAverage = d3.mean(coreValueSelfAnswers, getRating);

      // add properties into the CoreValue
      return Object.assign(coreValue, {
        questions: coreValueQuestions,
        peerRatingAverage,
        selfRatingAverage
      });
    });

    const selfInsightsScore = d3.mean(coreValues, coreValue => coreValue.selfRatingAverage);

    // Insights scores by Giver type (Manager, Direct Reports, Other Key Stakeholders)
    const insightsScoresByGiverType = [];
    Object.keys(peerReviewsByPeerType).forEach(key => {
      const peerReviewsList = peerReviewsByPeerType[key];
      const coreValueAnswers = getCoreValueAnswersFromPeerReviews(peerReviewsList);
      insightsScoresByGiverType.push({
        score: createInsightsScore(coreValueAnswers),
        key,
      });
    });
    // Add self as a giver type
    insightsScoresByGiverType.unshift({
      key: 'self',
      score: selfInsightsScore,
    });

    // Talents
    // ------------------------------------------------------------------------
    // all TalentAnswers from all peers
    const allPeerTalentAnswers = peerReviews.reduce((list, peerReview) => (
        list.concat(peerReview.talentAnswers)
    ), []);
    // for all talents
    const talents = review.reviewCycle.talents.map(talent => {
      // get all peer talent answers for this talent
      const peerTalentAnswers = allPeerTalentAnswers.filter(ta => (
          ta.talent === talent.id
      ));

      // add to Talent
      return Object.assign(talent, {
        peerTalentAnswers
      });
    });

    // StoryWords
    // ------------------------------------------------------------------------
    // all StoryWords from all peers
    const allPeerStoryWords = peerReviews.reduce((list, peerReview) => (
        list.concat(peerReview.storyWords)
    ), []);
    const storyWordsByPeerType = {};
    PEER_TYPE_LIST.forEach(peerType => {
      storyWordsByPeerType[peerType] = allPeerStoryWords.filter(storyWord => (
        _.includes(peerReviewIdsByPeerType[peerType], storyWord.review)
      ));
    });

    this.setState({
      review: review,
      user: review.user,
      organization: review.reviewCycle.organization,
      coreValues,
      insightsScore,
      insightsScoresByGiverType,
      selfInsightsScore,
      talents,
      storyWordsByPeerType,
      hideTalents: review.reviewCycle.hideTalents
    });
  }
  componentDidMount () {
    axios.get(`${API_URI}/reports/${this.props.match.params.reportUUID}/`)
      .then((resp) => {
        this.prepareChartData(resp.data);
      })
      .catch(function (err) {
        console.log("err");
        console.log(err);
      });
  }
  render() {
    // use an array to render pages in a loop, inserting page numbers
    let pages = [
      {
        component: WhyFeedbackPage
      },
      {
        component: SettingSuccessPage
      },
      {
        component: HowToThinkPage
      },
      {
        component: CulturalNormsPage
      },
      {
        component: CoreValueAveragePage,
        props: {
          coreValues: this.state.coreValues,
          insightsScore: this.state.insightsScore,
        }
      },
      {
        component: FeedbackGiverCategoryPage,
        props: {
          insightsScoresByGiverType: this.state.insightsScoresByGiverType,
          selfInsightsScore: this.state.selfInsightsScore,
        }
      }
    ];
    pages = pages.concat(
      this.state.coreValues.map((coreValue, i) => (
          {
            component: CoreValuePage,
            props: {
              coreValue,
              index: i
            }
          }
      ))
    );
    pages = pages.concat([
      {
        component: MappingYourPerceptionsPage
      },
      {
        component: PerceptionsMapPage
      },
      ...(!this.state.hideTalents ?
        [{
          component: DevelopingLeadershipTalentsPage
        }] : []
      ),
      ...(!this.state.hideTalents ?
        [{
          component: TalentsChartPage,
          props: {
            talents: this.state.talents
          }
        }] : []
      ),
      ...(!this.state.hideTalents ?
        [{
          component: YourChosenTalentsPage,
          props: {
            review: this.state.review
          }
        }] : []
      ),
      {
        component: TakingActionsFeedbackPage,
        props: {
          hideTalent: this.state.hideTalents
        }
      },
      {
        component: BottomLinePage
      },
      {
        component: YourBrandInWordsPage,
        props: {
          review: this.state.review
        }
      },
      {
        component: StakeholdersPerspectivePage,
        props: {
          storyWordsByPeerType: this.state.storyWordsByPeerType
        }
      },
      {
        component: StakeholdersPerspectiveContinuedPage,
        props: {
          storyWordsByPeerType: this.state.storyWordsByPeerType
        }
      },
      {
        component: DefineOrOthersWillPage
      },
      {
        component: NextStepsPage
      },
    ]);
    return (
      <MuiThemeProvider theme={getMuiTheme()}>
        <div style={style}>
          <CoverPage user={this.state.user} />
          {pages.map((page, i) => {
            const PageComponent = page.component;
            return (
              <PageComponent
                key={`page_${i}`}
                user={this.state.user}
                organization={this.state.organization}
                pageNumber={i + 1}
                {...page.props || {}}
              />
            );
          })}
          <BackCoverInsidePage />
        </div>
      </MuiThemeProvider>
    );
  }
};

const style = {
  fontFamily: familyBook,
  fontSize: '0.14in',
  color: 'black'
};

export default Report;
