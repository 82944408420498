import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { find } from 'lodash';

import {
  ReviewHeading3,
  ReviewHeading4
} from '../../common/components/Headings';

import CoreValueResponse from './CoreValueResponse';
import { getPeerName } from './utils';

/**
 *  @class        CoreValueQuestionsAndResponses
 *
 *  @classdesc    Given a CoreValue, loops through rendering responses to
 *  each question.
 **/
const CoreValueQuestionsAndResponses = ({coreValue, review, orgStyles}) => (
  <div className={css(styles.container)}>
    <ReviewHeading3 additionalStyles={[styles.coreValueHeader]}>{coreValue.name}</ReviewHeading3>

    {coreValue.questions.map((question, i) => {
      const responses = [];
      // self review
      const selfReviewAnswer = find(review.coreValueAnswers, ['question', question.id]);
      responses.push({
        stakeholderName: 'Self Score',
        rating: selfReviewAnswer.rating,
        description: selfReviewAnswer.description,
      });
      // peer review responses
      for (const peerReview of review.peerReviews) {
        const answer = find(peerReview.coreValueAnswers, ['question', question.id]);
        if (answer) {
          responses.push({
            stakeholderName: getPeerName(review.reviewCycle.hidePeerNames, peerReview),
            rating: answer.rating,
            description: answer.description,
          });
        }
      }

      return (
        <div key={question.id} className={css(styles.questionContainer)}>
          <ReviewHeading4 additionalStyles={[orgStyles.secondaryColorText, styles.coreValueQuestionText]}>
            Q{i + 1}. {question.text}
          </ReviewHeading4>
          {responses.map(response => (
            <CoreValueResponse {...response} />
          ))}
        </div>
      );
    })}
  </div>
);

const styles = StyleSheet.create({
  container: {
    marginTop: '140px',
    // marginBottom: '140px',
  },
  coreValueHeader: {
    marginTop: 0,
    marginBottom: 0,
  },
  questionContainer: {
    marginTop: '80px',
    marginBottom: '140px',
  },
  coreValueQuestionText: {
    marginTop: 0,
    marginBottom: 0,
  }
});

export default CoreValueQuestionsAndResponses;
