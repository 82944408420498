import React from 'react';

import Page from './components/Page';
import Header from './components/Header';
import { ReportHeading } from './components/Headings';
import Paragraph from './components/Paragraph';
import ZeroPaddedIndex from '../common/components/ZeroPaddedIndex';
import { familyHeavy } from '../fonts';
import { sizeXl } from './fontSizes.js';
import { grayAccent, SELF_COLOR } from '../colors';

const styles = {
  rowContainer: {
    height: '4.75in',
  },
  talentName: {
    fontFamily: familyHeavy,
    fontSize: sizeXl,
    color: SELF_COLOR,
  },
  fullHeightContainer: {
    height: '100%',
  },
  rightColumn: {
    borderLeft: `1px solid ${grayAccent}`,
  },
  bottomRow: {
    borderTop: `1px solid ${grayAccent}`,
  },
};

const CenteredInColumn = ({ children }) => (
  <div
    style={styles.fullHeightContainer}
    className='d-flex align-items-center justify-content-center text-center'
  >
    {children}
  </div>
);

const YourChosenTalentsPage = ({ review, ...props }) => (
  <Page>
    <Header {...props} />

    {/* Layout assumes two talentAnswers only */}
    {review.talentAnswers.slice(0, 2).map((talentAnswer, i) => {
      const talent = review.reviewCycle.talents.find(
        talent => talent.id === talentAnswer.talent,
      );
      return (
        <div
          key={talentAnswer.id}
          className='row pt-3 pb-3'
          style={Object.assign(
            {},
            styles.rowContainer,
            i === 1 ? styles.bottomRow : {},
          )}
        >
          <div className='col-5'>
            <ReportHeading marginBottom={'2em'}>
              Why You Chose Talent <ZeroPaddedIndex index={i} />
            </ReportHeading>
            <Paragraph>{talentAnswer.why}</Paragraph>
          </div>
          <div className='col-7' style={styles.rightColumn}>
            <ReportHeading>
              Your Selected Leadership Talent <ZeroPaddedIndex index={i} />
            </ReportHeading>
            <CenteredInColumn>
              <span style={styles.talentName}>{talent.name}</span>
            </CenteredInColumn>
          </div>
        </div>
      );
    })}
  </Page>
);

export default YourChosenTalentsPage;
