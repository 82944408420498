import React from 'react';

import Page from './components/Page';
import Header from './components/Header';
import ZeroPaddedIndex from '../common/components/ZeroPaddedIndex';
import {ReportHeading} from './components/Headings';
import HorizontalDivider from './components/HorizontalDivider';
import CoreValuePageScoreRow from './components/CoreValuePageScoreRow';
import CoreValueQuestionsPlot from './components/CoreValueQuestionsPlot/CoreValueQuestionsPlot';

import {PEER_TYPE, PEER_TYPE_LIST} from '../models';
import { PEER_TYPE_COLORS, SELF_COLOR } from '../colors';

const HorizontalDividerRow = () => (
  <div className="row">
    <div className="col">
      <HorizontalDivider />
    </div>
  </div>
);

const PEER_TYPE_LABELS = {
  [PEER_TYPE.MANAGER]: 'Manager',
  [PEER_TYPE.DIRECT_REPORT]: 'Dir. Reports',
  [PEER_TYPE.ADDITIONAL]: 'Others'
};

const lineHeightEm = 1.5;
const numLines = 7;
const styles = {
  coreValueDescription: {
    // this truncates the label if more than 6 lines using CSS.  Currently
    // does not add an ellipsis or anything, which is a bit strange but this
    // is a corner case.
    display: 'block',
    wordWrap: 'break-word',
    overflow: 'hidden',
    maxHeight: `${lineHeightEm * numLines}em`,
    lineHeight: `${lineHeightEm}em`,
    marginTop: '1em'
  },
  legendSubheading: {
    fontStyle: 'italic',
    marginTop: '0.8em'
  }
};

/**
 *  @class        CoreValuePage
 *
 *  @classdesc    An instance of this is rendered for each CoreValue.
 **/
const CoreValuePage = ({coreValue, index, ...props}) => (
  <Page>

    <Header {...props} />

    <div className="row">
      <div className="col">
        <ReportHeading sizeLg={true}>
          {coreValue.name}<br />No. <ZeroPaddedIndex index={index} />
        </ReportHeading>
        <ReportHeading sizeLg={true} accentGray={true}>
          Overview
        </ReportHeading>
      </div>
      <div className="col">
        <ReportHeading>Why does this cultural norm matter?</ReportHeading>
        <p style={styles.coreValueDescription}>{coreValue.description}</p>
      </div>
    </div>

    <div className="row">
      <div className="col">
        <ReportHeading>Your Score</ReportHeading>
      </div>
    </div>

    <HorizontalDividerRow />

    <CoreValuePageScoreRow coreValue={coreValue} />

    <HorizontalDividerRow />

    <div className="row mt-4 mb-5">
      <div className="col">
        <ReportHeading>Question by Question Breakdown</ReportHeading>
        <p style={styles.legendSubheading}>
          Numbers in dots indicate frequency.
        </p>
      </div>
      <div className="col">
        <div className="row text-center">
          <div className="col m-0 p-0">
            <ReportHeading inline={true} color={SELF_COLOR}>
              Self
            </ReportHeading>
          </div>
          {PEER_TYPE_LIST.map(peerType => (
              <div className="col m-0 p-0" key={`peer_labels_${peerType}`}>
                <ReportHeading
                  inline={true}
                  color={PEER_TYPE_COLORS[peerType]}
                >
                  {PEER_TYPE_LABELS[peerType]}
                </ReportHeading>
              </div>
          ))}
        </div>
      </div>
    </div>

    <CoreValueQuestionsPlot
      width={575}
      height={220}
      coreValue={coreValue}
    />

  </Page>
);

export default CoreValuePage;
