import React from 'react';

import { StyleSheet, css } from 'aphrodite';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const ExistingReviewersSection = ({
  existingReviewers
}) => (
  <div>
    {existingReviewers.map((answer, i) => {
      return (
        <div className={css(styles.reviewerRow)} key={i}>
          <div className={css(styles.reviewerName)}>{answer.firstName} {answer.lastName}</div>
          <div className={css(styles.submitted)}>
            Submitted <CheckCircleOutlineIcon style={{ fontSize: "20px" }} />
            </div>
        </div>
      )
    })}
  </div>
);

const styles = StyleSheet.create({
  reviewerRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: 'solid 1px black',
    marginTop: '38px',
    paddingBottom: '20px',
  },
  reviewerName: {
    fontSize: '21px',
    letterSpacing: '2.21px',
  },
  submitted: {
    fontSize: '15px',
    letterSpacing: '1.6px',
  }
})

export default ExistingReviewersSection;
