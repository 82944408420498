import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import GoalModal from './GoalModal';
import TimeRemaining from './TimeRemaining';
import MainColumn from '../../common/components/MainColumn';

/**
 *  @class        ReviewMainColumn
 *
 *  @classdesc    A wrapper used on every Review page to add the
 *  "time remaining" in the upper right, modal button and the size of the
 *  column of text relative to the whole main section.
 **/
const ReviewMainColumn = ({review, children, currentPageNum}) => (
  <div>
    <div
      className={`row d-flex align-items-center ${css(styles.headerContainer)}`}
    >
      <div className="col-8">
        <GoalModal review={review} />
      </div>
      <div className="col-4">
        <TimeRemaining review={review} currentPageNum={currentPageNum} />
      </div>
    </div>
    <MainColumn>
      {children}
    </MainColumn>
  </div>
);

const styles = StyleSheet.create({
  container: {
    paddingBottom: '6em'
  },
  headerContainer: {
    height: '100px'
  }
});

export default ReviewMainColumn;
