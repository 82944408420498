import React from 'react';
import { StyleSheet, css } from 'aphrodite';

import {
  ReviewHeading4
} from '../../common/components/Headings';

const noAnswerText = 'No qualitative response.';

/**
 *  @class        CoreValueResponse
 *
 *  @classdesc    Displays a peer name and CoreValueAnswer for a given question.
 **/
const CoreValueResponse = ({
  stakeholderName,
  rating,
  description,
}) => {
  return (
    <div>
      <ReviewHeading4 additionalStyles={[styles.peerAnswerHeading]}>
        <span className={css(styles.peerName)}>
          {stakeholderName}
        </span>
        <span>-</span>
        <span className={css(styles.peerRating)}>
          {rating}
        </span>
      </ReviewHeading4>
      <p>
        {description === '' ? noAnswerText : description}
      </p>
    </div>
  );
};

export default CoreValueResponse;

const dashSpacing = '0.25em';

const styles = StyleSheet.create({
  peerName: {
    marginRight: dashSpacing
  },
  peerRating: {
    marginLeft: dashSpacing
  },
  peerAnswerHeading: {
    marginTop: 80,
    marginBottom: 10,
  }
});
