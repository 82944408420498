import React from 'react';

import Page from './components/Page';
import Header from './components/Header';
import HorizontalDivider from './components/HorizontalDivider';
import VerticalDivider from './components/VerticalDivider';

import { familyHeavy } from '../fonts';
import { sizeLg } from './fontSizes';

const styles = {
  container: {
    marginTop: '3in'
  },
  topColumnLabel: {
    fontFamily: familyHeavy,
  },
  leftRowLabel: {
    fontFamily: familyHeavy,
    fontSize: sizeLg,
    textAlign: 'right'
  },
  mappingEntryRow: {
    height: '2.25in'
  },
  topDividerRowContainer: {
    marginBottom: '1.5em'
  },
  middleDividerRowContainer: {
    margin: '1.5em 0 1.5em 0'
  },
  bottomDividerRowContainer: {
    marginTop: '1.5em'
  }
};

const HorizontalDividerRow = ({ style }) => (
  <div className="row" style={style}>
    <div className="col-3"></div>
    <div className="col-9">
      <HorizontalDivider />
    </div>
  </div>
);

const MappingEntryRow = ({ label }) => (
  <div className="row" style={styles.mappingEntryRow}>
    <div className="col-3 d-flex align-items-center justify-content-end">
      <span style={styles.leftRowLabel}>{label}</span>
    </div>
    <div className="col-3">
    </div>
    <div className="col-3">
      <VerticalDivider />
    </div>
    <div className="col-3">
      <VerticalDivider />
    </div>
  </div>
);

const PerceptionsMapPage = ({...props}) => (
  <Page>
    <Header {...props} />
    <div className="row" style={styles.container}>
      <div className="col-3"></div>
      <div className="col-3">
        <span style={styles.topColumnLabel}>Strength</span>
      </div>
      <div className="col-3">
        <span style={styles.topColumnLabel}>Challenge</span>
      </div>
      <div className="col-3">
        <span style={styles.topColumnLabel}>Opportunity</span>
      </div>
    </div>
    <HorizontalDividerRow style={styles.topDividerRowContainer}/>
    <MappingEntryRow label="Surprise" />
    <HorizontalDividerRow style={styles.middleDividerRowContainer}/>
    <MappingEntryRow label="Not a Surprise" />
    <HorizontalDividerRow style={styles.bottomDividerRowContainer}/>
  </Page>
);

export default PerceptionsMapPage;
