import React from 'react';
import { isObjectEmpty } from '../utils';

export default class FormErrorChecker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    }
  }

  componentDidUpdate() {
    const {
      isSubmitting,
      errors
    } = this.props;

    const { hasError } = this.state;

    if (!hasError && isSubmitting && !isObjectEmpty(errors)) {
      this.setState({ hasError: true });
    } else if (hasError && isSubmitting && isObjectEmpty(errors)) {
      this.setState({ hasError: false }); 
    }
  }

  render() {
    const { hasError } = this.state;

    return (
      this.props.children(hasError)
    );
  }
}
