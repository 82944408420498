import React from 'react';
import InfoPage from './components/InfoPage';
import InfoPageHeading from './components/InfoPageHeading';
import InfoPageSubheading from './components/InfoPageSubheading';
import Paragraph from './components/Paragraph';

const NextStepsPage = ({...props}) => (
  <InfoPage {...props}>
    <InfoPageHeading>Your Next
      Steps to Take</InfoPageHeading>

    <InfoPageSubheading>Remember, you are the  driver of your Guide Insights experience and the only one who has the data and these insights. Consider taking these steps to put it into action.</InfoPageSubheading>

    <Paragraph>
      Send a thank you to your responders.
    </Paragraph>

    <Paragraph>
      Schedule time with your manager to share what you have learned and get their perspective on development strategies.
    </Paragraph>

    <Paragraph>
      Do not simply rely on the report. Reach out to other stakeholders for meaningful conversations aimed at reaching deeper insights.
    </Paragraph>

    <Paragraph>
      Book a 1:1 with Blank Page to take your action planning to the next level.
    </Paragraph>

    <Paragraph heavy={true} accent={true}>
      Own the Outcome
    </Paragraph>

    <Paragraph heavy={true}>
      What will I see, hear or feel that will indicate I am making progress?
    </Paragraph>

  </InfoPage>
);

export default NextStepsPage;
