import React from 'react';
import { StyleSheet, css } from 'aphrodite';

import { familyHeavy } from '../../fonts';
import NavigationCheckIcon from './NavigationCheckIcon';
import { getReviewSections, REVIEW_SECTION_TYPE, REVIEW_TYPE } from '../../models';

const REVIEW_SECTION_LABELS = {
  [REVIEW_SECTION_TYPE.INTRODUCTION]: 'Introduction',
  [REVIEW_SECTION_TYPE.ORGANIZATION]: 'Norms',
  [REVIEW_SECTION_TYPE.PERSONAL]: 'Talents',
  [REVIEW_SECTION_TYPE.STAKEHOLDERS]: 'Stakeholders'
};

/**
 *  @class        Navigation
 *
 *  @classdesc    Navbar on every Review page.  Displays progress of each
 *  review section.
 **/

class Navigation extends React.Component {
  render() {
    const { review, orgStyles, pageNum, hideTalents } = this.props;
    const reviewSections = getReviewSections(review, pageNum + 1);
    const { reviewType } = review;
    if (hideTalents) {
      REVIEW_SECTION_LABELS[REVIEW_SECTION_TYPE.PERSONAL] = reviewType === REVIEW_TYPE.SELF_REVIEW ? 'Your Brand' : 'Brand';
    }

    return (
      <div className={css(styles.navContainer)}>
        <ul className="nav flex-column">
          {reviewSections.map((reviewSection, i) => {
            let className = 'nav-item d-flex align-items-center ';
            if (reviewSection.active) {
              className += 'active ' + css(
                styles.reviewSection,
                styles.navItemActive,
                orgStyles.secondaryColorText
              );
            } else {
              className += css(styles.reviewSection);
            }
            return (
              <div key={`navitem_${i}`}>
                <li className={className}>
                  <span>{REVIEW_SECTION_LABELS[reviewSection.type]}</span>
                  <NavigationCheckIcon reviewSection={reviewSection} />
                </li>
                {i < reviewSections.length - 1 && (
                  <div className={css(styles.navSpacer)}>
                    <div className={css(styles.navVerticalLineSegment)}></div>
                  </div>
                )}
            </div>
            );
          })}
        </ul>
        <div className={css(styles.navLogoContainer)}>
          <img
            className={css(styles.navLogo)}
            alt="Powered by Guide Insights"
            src={`${process.env.PUBLIC_URL}/assets/logos/powered-by-guide.png`}
            srcSet={`
                  ${process.env.PUBLIC_URL}/assets/logos/powered-by-guide@2x.png 2x,
                  ${process.env.PUBLIC_URL}/assets/logos/powered-by-guide@3x.png 3x
                `}
          />
        </div>
      </div>
    );
  }
};


const styles = StyleSheet.create({
  navContainer: {
    paddingTop: '168px',
    position: 'fixed',
    width: '210px',
  },
  reviewSection: {
    margin: '0 0 0 30px',
    height: '20px',
    lineHeight: '20px',
    letterSpacing: '1px',
    fontSize: '16px',
  },
  navItemActive: {
    fontFamily: familyHeavy
  },
  navLogoContainer: {
    margin: '228px 0 228px 0',
  },
  navLogo: {
    display: 'block',
    margin: 'auto'
  },
  navSpacer: {
    height: '44px',
    width: '100%',
    position: 'relative'
  },
  navVerticalLineSegment: {
    width: '2px',
    height: '100%',
    backgroundColor: 'white',
    position: 'absolute',
    right: '1.2em'
  }
});
export default Navigation;
