/**
 *  @file     corevalue.js
 *
 *  @desc     Form validation logic and form field helper text for the 
 *  organization review pages.
 **/

import * as Yup from 'yup';

import {
  FIELD_REQUIRED_TEXT,
  makeCharLimitText
} from './common';

export const coreValueDescriptionCharLimit = 256;

/**
 *  Create the validation schema for the CoreValueAnswer form during the 
 *  "organizational" portion of the survey.  This schema depends on the
 *  CoreValueQuestion instances related to this CoreValue.
 *
 *  @param  {Object}  coreValue - a CoreValue model instance.
 **/
export const createValidationSchema = function (coreValue) {
  return Yup.object().shape({
    coreValueAnswers: Yup.array()
    .min(coreValue.questions.length)
    .max(coreValue.questions.length)
    .of(
      Yup.object().shape({
        question: Yup.number(),
        rating: Yup.number()
        .strict(true)
        .integer()
        .min(1, FIELD_REQUIRED_TEXT)
        .max(5, FIELD_REQUIRED_TEXT)
        .required(FIELD_REQUIRED_TEXT),
        description: Yup.string()
        .max(
          coreValueDescriptionCharLimit,
          makeCharLimitText(coreValueDescriptionCharLimit)
        )
        .notRequired()
      })
    )
  });
};

export const createInitialValues = function (coreValue, previousAnswers = "") {
  if (previousAnswers === "") {
    return {
      coreValueAnswers: coreValue.questions.map((question, i) => (
        {
          question: question.id,
          rating: 0,
          description: ''
        }
      ))
    };
  } else {
    const coreValueAnswers = previousAnswers.map(
      ({ id, question, rating, description }) => ({
        id,
        question,
        rating,
        description,
      })
    );
    const sortedCoreValueAnswers = coreValueAnswers.sort((a,b) => a.question > b.question ? 1 : -1);
    return { coreValueAnswers: sortedCoreValueAnswers};
  }
};
