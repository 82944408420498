import React from 'react';
import { getIn } from 'formik';
import { StyleSheet, css } from 'aphrodite';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Radio from '@material-ui/core/Radio';
import DoneIcon from '@material-ui/icons/Done';
import FormHelperText from '@material-ui/core/FormHelperText';
import { infoGrayText } from '../../colors';

/**
 *  @class        RatingFormField
 *
 *  @classdesc    Custom rating form field, functions like Radio buttons but
 *  displayed as squares that look like Material "paper".
 **/
class RatingFormField extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      hoveringOptionIndex: null
    };

    this.handleMouseEnter = (i) => {
      this.setState({
        hoveringOptionIndex: i
      });
    };
    this.handleMouseLeave = (i) => {
      this.setState({
        hoveringOptionIndex: null
      });
    };
  }
  render() {
    const { field, form, orgStyles } = this.props;


    /**
     *  Because of the material-ui Radio (and HTML5 radio input), the output
     *  value of the form field must be a string.
     **/
    const options = [
      {
        value: "1",
        helperText: "Missing expectations"
      },
      {
        value: "2",
        helperText: "Meeting expectations on occasion"
      },
      {
        value: "3",
        helperText: "Meeting expectations"
      },
      {
        value: "4",
        helperText: "Exceeding expectations on occasion"
      },
      {
        value: "5",
        helperText: "Exceeding expectations"
      }
    ];

    const touched = getIn(form.touched, field.name);
    const errors = getIn(form.errors, field.name);

    const fieldNumValue = Number(field.value);
    let helperText = "Hover over or tap a square to see more info on the rating";
    if (errors) {
      helperText = errors;
    } else if (this.state.hoveringOptionIndex !== null) {
      helperText = options[this.state.hoveringOptionIndex].helperText;
    } else if (fieldNumValue > 0) {
      helperText = options[fieldNumValue - 1].helperText;
    }
    
    const selected = (
      <Card
        className={css(
          styles.card,
          styles.cardChecked,
          styles.cardHighlighted,
          orgStyles.secondaryColorBorder
        )}
      >
        <DoneIcon className={css(styles.checkedIcon)} color="action" />
      </Card>
    );


    return (
      <div>
        <div className={css(styles.ratingLabelContainer)}>
          <div className={css(styles.ratingLabel, styles.ratingLabelLeft)}>
            <label>Missing<br/>Expectations</label>
          </div>
          <div className={css(styles.ratingLabel, styles.ratingLabelCenter)}>
            <label>Meeting<br/>Expectations</label>
          </div>
          <div className={css(styles.ratingLabel, styles.ratingLabelRight)}>
            <label>Exceeding<br/>Expectations</label>
          </div>
        </div>
        <div className={css(styles.ratingInputContainer)}>
        {options.map((option, i) => (
            <Radio
              key={i}
              checked={field.value.toString() === option.value}
              onChange={field.onChange}
              value={option.value}
              color="default"
              name={field.name}
              aria-label={option.value}
              icon={
                <Card
                  className={
                    css(
                      styles.card,
                      (
                        this.state.hoveringOptionIndex === i
                      ) && styles.cardHighlighted,
                      (
                        this.state.hoveringOptionIndex === i
                        && orgStyles.secondaryColorBorder
                      )
                    )
                  }
                >
                  <CardContent></CardContent>
                </Card>
              }
              checkedIcon={selected}
              className={css(styles.radio)}
              onMouseEnter={() => this.handleMouseEnter(i)}
              onMouseLeave={() => this.handleMouseLeave(i)}
              disableRipple={true}
            />
        ))}
        </div>
        <FormHelperText error={Boolean(touched && errors)}>
          {helperText}
        </FormHelperText>
      </div>
    );
  }
};

const styles = StyleSheet.create({
  ratingInputContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  radio: {
    margin: '0 0px 0 0',
    padding: 0,
    width: '102px',
  },
  card: {
    maxWidth: '100px',
    width: '100%',
    height: '80px',
    transition: 'transform 300ms ease',
    borderRadius: 0,
  },
  cardHighlighted: {
    border: '1px solid',
  },
  checkedIcon: {
    marginTop: '20%',
    width: '50%',
    height: '50%'
  },
  ratingLabelContainer: {
    display: 'flex',
    width: '508px', //TODO: need to see how this works on mobile
  },
  ratingLabel: {
    lineHeight: '21px',
    fontSize: '15px',
    color: infoGrayText
  },
  ratingLabelLeft: {
    flex: '1 0 0px',
    textAlign: 'left'
  },
  ratingLabelCenter: {
    flex: '3 0 0px',
    textAlign: 'center'
  },
  ratingLabelRight: {
    flex: '1 0 0px',
    textAlign: 'right'
  }
});

export default RatingFormField;
